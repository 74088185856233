import React from "react";
import "react-multi-carousel/lib/styles.css";
import { Color, Color as Colors } from "../../src/utils/Color";
import styled  from "styled-components";
import _ from "lodash";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomFont from "../../components/CustomFont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { locations } from "../../src/utils/InternalLinkageData";

const FooterStateContainer = styled.div`
  font-size: 0.9rem;
  margin: 7px 0;

  &:hover {
    cursor: pointer;
  }
`;

const FooterStateContentSub = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  max-height: 0;
  overflow: hidden;

  &.expanded {
    max-height: 1000px;
  }

  & a {
    font-size: 12px;
    padding-top: 5px;
  }
    
  & a:hover {
    cursor: pointer;
    color: ${Color.primaryColor};
    text-decoration: underline;
  }
`;

const LocationSection = styled.div`
  &.hidden {
    display: none;
  }
`;

class FooterLocationDesktop extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewMore: false,
      expandedLocations: new Set(),
      viewMore2: false,
      viewMore3: false,
      viewMore4: false,

    };
  }

  // onClickPropertyForSale = e => {
  //    const { clearFilterData } = props;
  //    e.preventDefault();
  //    clearFilterData && clearFilterData();
  //
  //    Router.push({
  //      pathname: "/property-for-sale"
  //    });
  //  };
  //
  //
  //  onClickPropertyForRent = e => {
  //    const { clearFilterData } = props;
  //    e.preventDefault();
  //    clearFilterData && clearFilterData();
  //
  //    Router.push({
  //      pathname: "/property-for-rent"
  //    });
  //  };

  // onClickPropertyRentState = (e, coordinateData) => {
  //   const { setCoordinateData } = this.props;

  //   e.preventDefault();

  //   setCoordinateData && setCoordinateData(coordinateData);
  //   Router.push({
  //     pathname: "/property-for-rent"
  //   });
  // };

  // onClickPropertySaleState = (e, coordinateData) => {
  //   const { setCoordinateData } = this.props;

  //   e.preventDefault();

  //   setCoordinateData && setCoordinateData(coordinateData);

  //   Router.push({
  //     pathname: "/property-for-sale"
  //   });
  //   console.log(coordinateData);
  // };

  toggleLocation = (locationId) => {
    this.setState(prevState => {
      const newSet = new Set(prevState.expandedLocations);
      if (newSet.has(locationId)) {
        newSet.delete(locationId);
      } else {
        newSet.add(locationId);
      }
      return { expandedLocations: newSet };
    });
  };

  toggleViewMore = () => {
    this.setState(prevState => ({
      viewMore: !prevState.viewMore
    }));
  };

  toggleViewMore2 = () => {
    this.setState(prevState => ({
      viewMore2: !prevState.viewMore2
    }));
  };

  toggleViewMore3 = () => {
    this.setState(prevState => ({
      viewMore3: !prevState.viewMore3
    }));
  };

  toggleViewMore4 = () => {
    this.setState(prevState => ({
      viewMore4: !prevState.viewMore4
    }));
  };

  render() {
    const { viewMore, viewMore2, viewMore3, viewMore4, expandedLocations } = this.state;
    const visibleLocations = locations.slice(0, 4);
    const hiddenLocations = locations.slice(4);
    return (
      <Box sx={{ borderTop: "1px solid rgb(221, 221, 221)" }}>
        <Container>

            <Grid container style={{ marginTop: "2rem" }} spacing={2}>
              <Grid item md={3} sm={3}>
                <CustomFont size="normal" styles={{ fontWeight: "500", marginBottom: "10px" }}>
                  Top Searched Property For Sale
                </CustomFont>
                <div>
                  {visibleLocations.map((location, index) => (
                    <FooterStateContainer key={`visible-${index}`}>
                      <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}                     
                        onClick={() => this.toggleLocation(`sale-${location.areaName}`)}
                      >
                        <p style={{ margin: "0" }}>Properties For Sale in {location.areaName}</p>
                        {expandedLocations.has(`sale-${location.areaName}`) ? 
                        <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : 
                        <ExpandMoreIcon style={{fontSize: "1.3rem"}} />
                        }
                      </div>
                      <FooterStateContentSub className={expandedLocations.has(`sale-${location.areaName}`) ? 'expanded' : ''}>
                        {location.cities.map((city, index) => (
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/${location.stateId}/${city.id}`} key={city.id + "-sale"}>
                            Properties for Sale in {city.name}
                          </a>
                        ))}
                      </FooterStateContentSub>
                    </FooterStateContainer>
                  ))}
                  <LocationSection className={!viewMore ? 'hidden' : ''}>
                    {hiddenLocations.map((location, index) => (
                      <FooterStateContainer key={`hidden-${index}`}>
                        <div 
                          style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}
                          onClick={() => this.toggleLocation(`sale-${location.areaName}`)}
                        >
                          <p style={{ margin: "0" }}>Properties for Sale in {location.areaName}</p>
                          {expandedLocations.has(`sale-${location.areaName}`) ? 
                            <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : 
                            <ExpandMoreIcon style={{fontSize: "1.3rem"}} />
                          }
                        </div>
                        <FooterStateContentSub className={expandedLocations.has(`sale-${location.areaName}`) ? 'expanded' : ''}>
                          {location.cities.map((city, index) => (
                            <a href={`${process.env.WEBSITE_URL}property-for-sale/${location.stateId}/${city.id}`} key={city.id + "-sale"}>
                              Properties for Sale in {city.name}
                            </a>
                          ))}
                        </FooterStateContentSub>
                      </FooterStateContainer>
                    ))}
                  </LocationSection>

                  <div 
                    style={{ 
                      textAlign: "left", 
                      marginTop: "1rem", 
                      marginBottom: "1rem", 
                      color: Colors.primaryColor, 
                      cursor: "pointer", 
                      fontSize: "0.9rem" 
                    }} 
                    onClick={this.toggleViewMore}
                  >
                    {viewMore ? "View Less" : "View More"}
                  </div>
                </div>
              </Grid>
              <Grid item md={3} sm={3}>
                <CustomFont size="normal" styles={{ fontWeight: "500", marginBottom: "10px" }}>
                  Top Searched Property For Rent
                </CustomFont>
                <div>
                  {visibleLocations.map((location, index) => (
                    <FooterStateContainer key={`visible-${index}`}>
                      <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}                     
                        onClick={() => this.toggleLocation(`rent-${location.areaName}`)}
                      >
                        <p style={{ margin: "0" }}>Properties For Rent in {location.areaName}</p>
                        {expandedLocations.has(`rent-${location.areaName}`) ? 
                        <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : 
                        <ExpandMoreIcon style={{fontSize: "1.3rem"}} />
                        }
                      </div>
                      <FooterStateContentSub className={expandedLocations.has(`rent-${location.areaName}`) ? 'expanded' : ''}>
                        {location.cities.map((city, index) => (
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/${location.stateId}/${city.id}`} key={city.id + "-rent"}>
                            Properties for Rent in {city.name}
                          </a>
                        ))}
                      </FooterStateContentSub>
                    </FooterStateContainer>
                  ))}
                  <LocationSection className={!viewMore2 ? 'hidden' : ''}>
                    {hiddenLocations.map((location, index) => (
                      <FooterStateContainer key={`hidden-${index}`}>
                        <div 
                          style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}
                          onClick={() => this.toggleLocation(`rent-${location.areaName}`)}
                        >
                          <p style={{ margin: "0" }}>Properties for Rent in {location.areaName}</p>
                          {expandedLocations.has(`rent-${location.areaName}`) ? 
                            <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : 
                            <ExpandMoreIcon style={{fontSize: "1.3rem"}} />
                          }
                        </div>
                        <FooterStateContentSub className={expandedLocations.has(`rent-${location.areaName}`) ? 'expanded' : ''}>
                          {location.cities.map((city, index) => (
                            <a href={`${process.env.WEBSITE_URL}property-for-rent/${location.stateId}/${city.id}`} key={city.id + "-rent"}>
                              Properties for Rent in {city.name}
                            </a>
                          ))}
                        </FooterStateContentSub>
                      </FooterStateContainer>
                    ))}
                  </LocationSection>

                  <div 
                    style={{ 
                      textAlign: "left", 
                      marginTop: "1rem", 
                      marginBottom: "1rem", 
                      color: Colors.primaryColor, 
                      cursor: "pointer", 
                      fontSize: "0.9rem" 
                    }} 
                    onClick={this.toggleViewMore2}
                  >
                    {viewMore2 ? "View Less" : "View More"}
                  </div>
                </div>
              </Grid>

              <Grid item md={3} sm={3}>
                <CustomFont size="normal" styles={{ fontWeight: "500", marginBottom: "10px" }}>
                  Malaysia Property For Sale
                </CustomFont>
                <div>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor`}>
                      Properties For Sale in Selangor
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-sale/perak`}>
                      Properties For Sale in Perak
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-sale/sabah`}>
                      Properties For Sale in Sabah
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-sale/kelantan`}>
                      Properties For Sale in Kelantan
                    </a>
                  </FooterStateContainer>
                    <LocationSection className={!viewMore3 ? 'hidden' : ''}>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/johor`}>
                          Properties For Sale in Johor
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/melaka`}>
                          Properties For Sale in Melaka
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/perlis`}>
                          Properties For Sale in Perlis
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/penang`}>
                          Properties For Sale in Penang
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang`}>
                          Properties For Sale in Pahang
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/putrajaya`}>
                          Properties For Sale in Putrajaya
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/labuan`}>
                          Properties For Sale in Labuan
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur`}>
                          Properties For Sale in Kuala Lumpur
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/negeri-sembilan`}>
                          Properties For Sale in Negeri Sembilan
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/sarawak`}>
                          Properties For Sale in Sarawak
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/terengganu`}>
                          Properties For Sale in Terengganu
                        </a>
                      </FooterStateContainer>
                    </LocationSection>
                  <div 
                    style={{ 
                      textAlign: "left", 
                      marginTop: "1rem", 
                      marginBottom: "1rem", 
                      color: Colors.primaryColor, 
                      cursor: "pointer", 
                      fontSize: "0.9rem" 
                    }} 
                    onClick={this.toggleViewMore3}
                  >
                    {viewMore3 ? "View Less" : "View More"}
                  </div>
                </div>
              </Grid>
              <Grid item md={3} sm={3}>
                <CustomFont size="normal" styles={{ fontWeight: "500", marginBottom: "10px" }}>
                  Malaysia Property For Rent
                </CustomFont>
                <div>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor`}>
                      Properties For Rent in Selangor
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-rent/perak`}>
                      Properties For Rent in Perak
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-rent/sabah`}>
                      Properties For Rent in Sabah
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-rent/kelantan`}>
                      Properties For Rent in Kelantan
                    </a>
                  </FooterStateContainer>
                    <LocationSection className={!viewMore4 ? 'hidden' : ''}>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/johor`}>
                          Properties For Rent in Johor
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/melaka`}>
                          Properties For Rent in Melaka
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/perlis`}>
                          Properties For Rent in Perlis
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/penang`}>
                          Properties For Rent in Penang
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang`}>
                          Properties For Rent in Pahang
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/putrajaya`}>
                          Properties For Rent in Putrajaya
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/labuan`}>
                          Properties For Rent in Labuan
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur`}>
                          Properties For Rent in Kuala Lumpur
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/negeri-sembilan`}>
                          Properties For Rent in Negeri Sembilan
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/sarawak`}>
                          Properties For Rent in Sarawak
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/terengganu`}>
                          Properties For Rent in Terengganu
                        </a>
                      </FooterStateContainer>
                    </LocationSection>
          
                          <div 
                    style={{ 
                      textAlign: "left", 
                      marginTop: "1rem", 
                      marginBottom: "1rem", 
                      color: Colors.primaryColor, 
                      cursor: "pointer", 
                      fontSize: "0.9rem" 
                    }} 
                    onClick={this.toggleViewMore4}
                  >
                    {viewMore4 ? "View Less" : "View More"}
                  </div>
                </div>
              </Grid>
            </Grid>

        </Container>
      </Box>
    );
  }
}

// function mapStateToProps(state) {
//   return {
//     authToken: selectors.getAuthToken(state)
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     clearFilterData: () => dispatch(searchActions.clearFilterData()),
//     setCoordinateData: coordinate =>
//       dispatch(searchActions.setCoordinateData(coordinate))
//   };
// }

// export default withTheme(
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(FooterLocationDesktop)
// );

export default FooterLocationDesktop;
