export const locations = [
  {
    stateId: `kuala-lumpur`,
    areaName: `Kuala Lumpur`,
    cities: [
      { id: `ampang-hilir`, name: `Ampang Hilir` },
      { id: `bangsar`, name: `Bangsar` },
      { id: `bukit-jalil`, name: `Bukit Jalil` },
      { id: `cheras`, name: `Cheras` },
      { id: `kepong`, name: `Kepong` },
      { id: `kerinchi`, name: `Kerinchi` },
      { id: `pudu`, name: `Pudu` },
      { id: `segambut`, name: `Segambut` },
      { id: `sentul`, name: `Sentul` },
      { id: `seputeh`, name: `Seputeh` },
    ],
  },
  {
    stateId: `selangor`,
    areaName: `Petaling Jaya`,
    cities: [
      { id: `kota-damansara`, name: `Kota Damansara` },
      { id: `subang-ss15`, name: `Subang SS15` },
      { id: `subang-jaya`, name: `Subang Jaya` },
      { id: `taipan`, name: `Taipan` },
      { id: `puchong`, name: `Puchong` },
      { id: `bandar-sunway`, name: `Bandar Sunway` },
      { id: `putra-heights`, name: `Putra Heights` },
      { id: `kelana-jaya`, name: `Kelana Jaya` },
      { id: `jalan-klang-lama`, name: `Jalan Klang Lama` },
      { id: `shah-alam`, name: `Shah Alam` },
    ],
  },
  {
    stateId: `johor`,
    areaName: `Johor Bahru`,
    cities: [
      { id: `permas-jaya`, name: `Permas Jaya` },
      { id: `larkin`, name: `Larkin` },
      { id: `ulu-tiram`, name: `Ulu Tiram` },
      { id: `taman-abad`, name: `Taman Abad` },
      { id: `stulang`, name: `Stulang` },
      { id: `perling`, name: `Perling` },
      { id: `kempas`, name: `Kempas` },
      { id: `skudai`, name: `Skudai` },
      { id: `kulai`, name: `Kulai` },
      { id: `pekan-nanas`, name: `Pekan Nanas` },
      { id: `austin-perdana`, name: `Austin Perdana` },
      { id: `bandar-dato-onn`, name: `Bandar Dato Onn` },
      { id: `bukit-indah`, name: `Bukit Indah` },
      { id: `danga-bay`, name: `Danga Bay` },
      { id: `forest-city`, name: `Forest City` },
      { id: `iskandar-puteri`, name: `Iskandar Puteri` },
      { id: `johor-jaya`, name: `Johor Jaya` },
      { id: `kota-iskandar`, name: `Kota Iskandar` },
      { id: `nusajaya`, name: `Nusajaya` },
    ],
  },
  {
    stateId: `negeri-sembilan`,
    areaName: `Seremban`,
    cities: [
      { id: `nilai-impian`, name: `Nilai Impian` },
      { id: `senawang`, name: `Senawang` },
      { id: `nilai`, name: `Nilai` },
      { id: `seremban-2`, name: `Seremban 2` },
      { id: `port-dickson`, name: `Port Dickson` },
      { id: `si-rusa`, name: `Si Rusa` },
      { id: `rantau`, name: `Rantau` },
      { id: `bandar-sri-sendayan`, name: `Bandar Sri Sendayan` },
      { id: `seremban`, name: `seremban` },
      { id: `labu`, name: `Labu` },
    ],
  },
  {
    stateId: `perak`,
    areaName: `Taiping`,
    cities: [
      { id: `simpang`, name: `Simpang` },
      { id: `ipoh`, name: `Ipoh` },
      { id: `matang`, name: `Matang` },
      { id: `changkat-jering`, name: `Changkat Jering` },
      { id: `changkat-keruing`, name: `Changkat Keruing` },
      { id: `simpang-pulai`, name: `Simpang Pulai` },
      { id: `gopeng`, name: `Gopeng` },
      { id: `malim-nawar`, name: `Malim Nawar` },
      { id: `jeram`, name: `Jeram` },
      { id: `meru`, name: `Meru` },
    ],
  },
  {
    stateId: `pahang`,
    areaName: `Pahang`,
    cities: [
      { id: `kuantan`, name: `Kuantan` },
      { id: `bentong`, name: `Bentong` },
      { id: `karak`, name: `karak` },
      { id: `mentakab`, name: `Mentakab` },
      { id: `bandar-bera`, name: `Bandar Bera` },
      { id: `raub`, name: `Raub` },
      { id: `kuala-krau`, name: `Kuala Krau` },
      { id: `pekan`, name: `Pekan` },
      { id: `sungai-lembing`, name: `Sungai Lembing` },
      { id: `temerloh`, name: `Temerloh` },
    ],
  },
  {
    stateId: `selangor`,
    areaName: `Klang Valley`,
    cities: [
      { id: `klia`, name: `KLIA` },
      { id: `kota-damansara`, name: `Kota Damansara` },
      { id: `kuala-selangor`, name: `Kuala Selangor` },
      { id: `petaling-jaya`, name: `Petaling Jaya` },
      { id: `port-klang`, name: `Port Klang` },
      { id: `puchong`, name: `Puchong` },
      { id: `puncak-alam`, name: `Puncak Alam` },
      { id: `semenyih`, name: `Semenyih` },
      { id: `sepang`, name: `Sepang` },
      { id: `serenia-city`, name: `Serenia City` },
    ],
  },
  {
    stateId: `sabah`,
    areaName: `Kota Kinabalu`,
    cities: [
      { id: `donggongon`, name: `Donggongon` },
      { id: `kota-kinabalu`, name: `Kota Kinabalu` },
      { id: `putatan`, name: `Putatan` },
      { id: `kudat`, name: `Kudat` },
      { id: `kundasang`, name: `Kundasang` },
      { id: `lahad-datu`, name: `Lahad Datu` },
      { id: `beaufort`, name: `Beaufort` },
      { id: `likas`, name: `Likas` },
      { id: `papar`, name: `Papar` },
      { id: `sandakan`, name: `Sandakan` },
    ],
  },
  {
    stateId: `selangor`,
    areaName: `Damansara`,
    cities: [
      { id: `damansara-selangor`, name: `Damansara Selangor` },
      { id: `damansara-perdana`, name: `Damansara Perdana` },
      { id: `damansara-utama`, name: `Damansara Utama` },
      { id: `kota-damansara`, name: `Kota Damansara` },
      { id: `taman-megah`, name: `Taman Megah` },
      { id: `bangi`, name: `Bangi` },
      { id: `cyberjaya`, name: `Cyberjaya` },
      { id: `damansara`, name: `Damansara` },
      { id: `glenmarie`, name: `Glenmarie` },
      { id: `kelana-jaya`, name: `Kelana Jaya` },
    ],
  },
  {
    stateId: `johor`,
    areaName: `Johor`,
    cities: [
      { id: `bakri`, name: `Bakri` },
      { id: `pagoh`, name: `Pagoh` },
      { id: `bukit-gambir`, name: `Bukit Gambir` },
      { id: `endau`, name: `Endau` },
      { id: `pontian`, name: `Pontian` },
      { id: `jementah`, name: `Jementah` },
      { id: `kempas`, name: `Kempas` },
      { id: `ledang`, name: `Ledang` },
      { id: `semerah`, name: `Semerah` },
      { id: `yong-peng`, name: `Yong Peng` },
    ],
  },
  {
    stateId: `penang`,
    areaName: `George Town`,
    cities: [
      { id: `air-itam`, name: `Air Itam` },
      { id: `komtar-tower`, name: `Komtar Tower` },
      { id: `seberang-jaya`, name: `Seberang Jaya` },
      { id: `jelutong`, name: `Jelutong` },
      { id: `penang-island`, name: `Penang Island` },
      { id: `gelugor`, name: `Gelugor` },
      { id: `tasek-gelugor`, name: `Tasek Gelugor` },
      { id: `simpang-ampat`, name: `Simpang Ampat` },
      { id: `nibong-tebal`, name: `Nibong Tebal` },
      { id: `bayan-lepas`, name: `Bayan Lepas` },
    ],
  },
  {
    stateId: `selangor`,
    areaName: `Selangor`,
    cities: [
      { id: `bandar-saujana`, name: `Bandar Saujana` },
      { id: `bangi`, name: `Bangi` },
      { id: `banting`, name: `Banting` },
      { id: `batu-cave`, name: `Batu Cave` },
      { id: `bukit-jelutong`, name: `Bukit Jelutong` },
      { id: `hulu-kelang`, name: `Hulu Kelang` },
      { id: `kajang`, name: `Kajang` },
      { id: `klang`, name: `Klang` },
      { id: `kota-warisan`, name: `Kota Warisan` },
      { id: `kundang`, name: `Kundang` },
    ],
  },
];

export const fixedLocations = [
  {
    name: "Bangsar",
    url: "in-bangsar-state-kuala-lumpur",
    featureName: "Fully Furnished Furnishing",
    featureUrl: "furnishing-fully-furnished",
  },
  {
    name: "Bukit Jalil",
    url: "in-bukit-jalil-state-kuala-lumpur",
    featureName: "Partially Furnished Furnishing",
    featureUrl: "furnishing-partially-furnished",
  },
  {
    name: "Klang",
    url: "in-klang-state-selangor",
    featureName: "Unfurnished Furnishing",
    featureUrl: "furnishing-unfurnished",
  },
  {
    name: "Sri Petaling",
    url: "in-sri-petaling-state-kuala-lumpur",
    featureName: "Freehold Tenure",
    featureUrl: "tenure-freehold",
  },
  {
    name: "Seremban",
    url: "in-seremban-state-negeri-sembilan",
    featureName: "Leasehold Tenure",
    featureUrl: "tenure-leasehold",
  },
  {
    name: "Kota Kinabalu",
    url: "in-kota-kinabalu-state-sabah",
    featureName: "Bumilot Tenure",
    featureUrl: "tenure-bumi-lot",
  },
  {
    name: "KLCC",
    url: "in-klcc-state-kuala-lumpur",
    featureName: "Min 1 Bedroom",
    featureUrl: "bedroom-min-1",
  },
  {
    name: "Bayan Lepas",
    url: "in-bayan-lepas-state-penang",
    featureName: "Min 2 Bedrooms",
    featureUrl: "bedroom-min-2",
  },
  {
    name: "Bukit Mertajam",
    url: "in-bukit-mertajam-state-penang",
    featureName: "Min 3 Bedrooms",
    featureUrl: "bedroom-min-3",
  },
  {
    name: "Ipoh",
    url: "in-ipoh-state-perak",
    featureName: "Min 4 Bedrooms",
    featureUrl: "bedroom-min-4",
  },
  {
    name: "Puchong",
    url: "in-puchong-state-selangor",
    featureName: "Min 1 Bathroom",
    featureUrl: "bathroom-min-1",
  },
  {
    name: "Iskandar Puteri",
    url: "in-iskandar-puteri-state-johor",
    featureName: "Min 2 Bathrooms",
    featureUrl: "bathroom-min-2",
  },
  {
    name: "Johor Bahru",
    url: "in-johor-bahru-state-johor",
    featureName: "Min 3 Bathrooms",
    featureUrl: "bathroom-min-3",
  },
  {
    name: "Kota Bharu",
    url: "in-kota-bharu-state-kelantan",
    featureName: "Min 4 Bathrooms",
    featureUrl: "bathroom-min-4",
  },
  {
    name: "Bangi",
    url: "in-bangi-state-selangor",
    featureName: "Min 5 Bathrooms",
    featureUrl: "bathroom-min-5",
  },
];

export const propertyType2025 = [
  {
    name: `1 Storey Terrace For Rent`,
    url: `${process.env.WEBSITE_URL}2025/1-storey-terrace-for-rent`,
  },
  {
    name: `1 Storey Terrace For Sale`,
    url: `${process.env.WEBSITE_URL}2025/1-storey-terrace-for-sale`,
  },
  {
    name: `2 Storey Semi Detached For Rent`,
    url: `${process.env.WEBSITE_URL}2025/2-storey-semi-detached-for-rent`,
  },
  {
    name: `2 Storey Semi Detached For Sale`,
    url: `${process.env.WEBSITE_URL}2025/2-storey-semi-detached-for-sale`,
  },
  {
    name: `2 Storey Terrace For Rent`,
    url: `${process.env.WEBSITE_URL}2025/2-storey-terrace-for-rent`,
  },
  {
    name: `2 Storey Terrace For Sale`,
    url: `${process.env.WEBSITE_URL}2025/2-storey-terrace-for-sale`,
  },
  {
    name: `3 Storey Shoplot For Rent`,
    url: `${process.env.WEBSITE_URL}2025/3-storey-shoplot-for-rent`,
  },
  {
    name: `3 Storey Shoplot For Sale`,
    url: `${process.env.WEBSITE_URL}2025/3-storey-shoplot-for-sale`,
  },
  {
    name: `Apartment Condo Service Residence For Rent`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent`,
  },
  {
    name: `Apartment Condo Service Residence For Sale`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-sale`,
  },
  {
    name: `Cluster Factory For Rent`,
    url: `${process.env.WEBSITE_URL}2025/cluster-factory-for-rent`,
  },
  {
    name: `Cluster Factory For Sale`,
    url: `${process.env.WEBSITE_URL}2025/cluster-factory-for-sale`,
  },
  {
    name: `Cluster House For Rent`,
    url: `${process.env.WEBSITE_URL}2025/cluster-house-for-rent`,
  },
  {
    name: `Cluster House For Sale`,
    url: `${process.env.WEBSITE_URL}2025/cluster-house-for-sale`,
  },
  {
    name: `Commercial For Sale`,
    url: `${process.env.WEBSITE_URL}2025/commercial-for-sale`,
  },
  {
    name: `Commercial For Rent`,
    url: `${process.env.WEBSITE_URL}2025/commercial-for-rent`,
  },
  {
    name: `Commercial Land For Rent`,
    url: `${process.env.WEBSITE_URL}2025/commercial-land-for-rent`,
  },
  {
    name: `Commercial Land For Sale`,
    url: `${process.env.WEBSITE_URL}2025/commercial-land-for-sale`,
  },
  {
    name: `Condo For Rent`,
    url: `${process.env.WEBSITE_URL}2025/condo-for-rent`,
  },
  {
    name: `Condo For Sale`,
    url: `${process.env.WEBSITE_URL}2025/condo-for-sale`,
  },
  {
    name: `Condominium For Rent`,
    url: `${process.env.WEBSITE_URL}2025/condominium-for-rent`,
  },
  {
    name: `Condominium For Sale`,
    url: `${process.env.WEBSITE_URL}2025/condominium-for-sale`,
  },
  {
    name: `Detached Factory For Rent`,
    url: `${process.env.WEBSITE_URL}2025/detached-factory-for-rent`,
  },
  {
    name: `Detached Factory For Sale`,
    url: `${process.env.WEBSITE_URL}2025/detached-factory-for-sale`,
  },
  {
    name: `Double 2 Storey Bungalow For Rent`,
    url: `${process.env.WEBSITE_URL}2025/double-2-storey-bungalow-for-rent`,
  },
  {
    name: `Double 2 Storey Bungalow For Sale`,
    url: `${process.env.WEBSITE_URL}2025/double-2-storey-bungalow-for-sale`,
  },
  {
    name: `Double 2 Storey Shoplot For Rent`,
    url: `${process.env.WEBSITE_URL}2025/double-2-storey-shoplot-for-rent`,
  },
  {
    name: `Double 2 Storey Shoplot For Sale`,
    url: `${process.env.WEBSITE_URL}2025/double-2-storey-shoplot-for-sale`,
  },
  {
    name: `Walk Up Apartment For Rent`,
    url: `${process.env.WEBSITE_URL}2025/walk-up-apartment-for-rent`,
  },
  {
    name: `Walk Up Apartment For Sale`,
    url: `${process.env.WEBSITE_URL}2025/walk-up-apartment-for-sale`,
  },
  {
    name: `Double Storey Semi D For Rent`,
    url: `${process.env.WEBSITE_URL}2025/double-storey-semi-d-for-rent`,
  },
  {
    name: `Double Storey Semi D For Sale`,
    url: `${process.env.WEBSITE_URL}2025/double-storey-semi-d-for-sale`,
  },
  {
    name: `Industrial For Sale`,
    url: `${process.env.WEBSITE_URL}2025/industrial-for-sale`,
  },
  {
    name: `Industrial For Rent`,
    url: `${process.env.WEBSITE_URL}2025/industrial-for-rent`,
  },
  {
    name: `Industrial Land For Sale`,
    url: `${process.env.WEBSITE_URL}2025/industrial-land-for-sale`,
  },
  {
    name: `Industrial Land For Rent`,
    url: `${process.env.WEBSITE_URL}2025/industrial-land-for-rent`,
  },
  {
    name: `Low Cost Apartment For Rent`,
    url: `${process.env.WEBSITE_URL}2025/low-cost-apartment-for-rent`,
  },
  {
    name: `Low Cost Apartment For Sale`,
    url: `${process.env.WEBSITE_URL}2025/low-cost-apartment-for-sale`,
  },
  {
    name: `Medium Industrial Factory For Rent`,
    url: `${process.env.WEBSITE_URL}2025/medium-industrial-factory-for-rent`,
  },
  {
    name: `Medium Industrial Factory For Sale`,
    url: `${process.env.WEBSITE_URL}2025/medium-industrial-factory-for-sale`,
  },
  {
    name: `Office For Rent`,
    url: `${process.env.WEBSITE_URL}2025/office-for-rent`,
  },
  {
    name: `Office For Sale`,
    url: `${process.env.WEBSITE_URL}2025/office-for-sale`,
  },
  {
    name: `Penthouses For Rent`,
    url: `${process.env.WEBSITE_URL}2025/penthouses-for-rent`,
  },
  {
    name: `Penthouses For Sale`,
    url: `${process.env.WEBSITE_URL}2025/penthouses-for-sale`,
  },
  {
    name: `Residential For Sale`,
    url: `${process.env.WEBSITE_URL}2025/residential-for-sale`,
  },
  {
    name: `Residential For Rent`,
    url: `${process.env.WEBSITE_URL}2025/residential-for-rent`,
  },
  {
    name: `Residential Land For Rent`,
    url: `${process.env.WEBSITE_URL}2025/residential-land-for-rent`,
  },
  {
    name: `Residential Land For Sale`,
    url: `${process.env.WEBSITE_URL}2025/residential-land-for-sale`,
  },
  {
    name: `Semi D Factory For Rent`,
    url: `${process.env.WEBSITE_URL}2025/semi-d-factory-for-rent`,
  },
  {
    name: `Semi D Factory For Sale`,
    url: `${process.env.WEBSITE_URL}2025/semi-d-factory-for-sale`,
  },
  {
    name: `Serviced Condo For Rent`,
    url: `${process.env.WEBSITE_URL}2025/serviced-condo-for-rent`,
  },
  {
    name: `Serviced Condo For Sale`,
    url: `${process.env.WEBSITE_URL}2025/serviced-condo-for-sale`,
  },
  {
    name: `Shop Office For Rent`,
    url: `${process.env.WEBSITE_URL}2025/shop-office-for-rent`,
  },
  {
    name: `Shop Office For Sale`,
    url: `${process.env.WEBSITE_URL}2025/shop-office-for-sale`,
  },
  {
    name: `Shoplot For Rent`,
    url: `${process.env.WEBSITE_URL}2025/shoplot-for-rent`,
  },
  {
    name: `Shoplot For Sale`,
    url: `${process.env.WEBSITE_URL}2025/shoplot-for-sale`,
  },
  {
    name: `Single Storey Bungalow For Rent`,
    url: `${process.env.WEBSITE_URL}2025/single-storey-bungalow-for-rent`,
  },
  {
    name: `Single Storey Bungalow For Sale`,
    url: `${process.env.WEBSITE_URL}2025/single-storey-bungalow-for-sale`,
  },
  {
    name: `Sofo For Rent`,
    url: `${process.env.WEBSITE_URL}2025/sofo-for-rent`,
  },
  {
    name: `Sofo For Sale`,
    url: `${process.env.WEBSITE_URL}2025/sofo-for-sale`,
  },
  {
    name: `Soho For Rent`,
    url: `${process.env.WEBSITE_URL}2025/soho-for-rent`,
  },
  {
    name: `Soho For Sale`,
    url: `${process.env.WEBSITE_URL}2025/soho-for-sale`,
  },
  {
    name: `Sovo For Rent`,
    url: `${process.env.WEBSITE_URL}2025/sovo-for-rent`,
  },
  {
    name: `Sovo For Sale`,
    url: `${process.env.WEBSITE_URL}2025/sovo-for-sale`,
  },
  {
    name: `Studio Apartment For Rent`,
    url: `${process.env.WEBSITE_URL}2025/studio-apartment-for-rent`,
  },
  {
    name: `Studio Apartment For Sale`,
    url: `${process.env.WEBSITE_URL}2025/studio-apartment-for-sale`,
  },
  {
    name: `Terrace Factory For Rent`,
    url: `${process.env.WEBSITE_URL}2025/terrace-factory-for-rent`,
  },
  {
    name: `Terrace Factory For Sale`,
    url: `${process.env.WEBSITE_URL}2025/terrace-factory-for-sale`,
  },
  {
    name: `Bungalow For Sale`,
    url: `${process.env.WEBSITE_URL}2025/bungalow-for-sale`,
  },
  {
    name: `Bungalow For Rent`,
    url: `${process.env.WEBSITE_URL}2025/bungalow-for-rent`,
  },
  {
    name: `2 Storey Shoplot For Rent`,
    url: `${process.env.WEBSITE_URL}2025/2-storey-shoplot-for-rent`,
  },
  {
    name: `2 Storey Shoplot For Sale`,
    url: `${process.env.WEBSITE_URL}2025/2-storey-shoplot-for-sale`,
  },
  {
    name: `Commercial Property For Sale`,
    url: `${process.env.WEBSITE_URL}2025/commercial-property-for-sale`,
  },
  {
    name: `Commercial Property For Rent`,
    url: `${process.env.WEBSITE_URL}2025/commercial-property-for-rent`,
  },
  {
    name: `Industrial Property For Sale`,
    url: `${process.env.WEBSITE_URL}2025/industrial-property-for-sale`,
  },
  {
    name: `Industrial Property For Rent`,
    url: `${process.env.WEBSITE_URL}2025/industrial-property-for-rent`,
  },
  {
    name: `Residential Property For Sale`,
    url: `${process.env.WEBSITE_URL}2025/residential-property-for-sale`,
  },
  {
    name: `Residential Property For Rent`,
    url: `${process.env.WEBSITE_URL}2025/residential-property-for-rent`,
  },
  {
    name: `Agricultural Property For Sale`,
    url: `${process.env.WEBSITE_URL}2025/agricultural-property-for-sale`,
  },
  {
    name: `Agricultural Property For Rent`,
    url: `${process.env.WEBSITE_URL}2025/agricultural-property-for-rent`,
  },
  {
    name: `Agricultural Land For Rent`,
    url: `${process.env.WEBSITE_URL}2025/agricultural-land-for-rent`,
  },
  {
    name: `Agricultural Land For Sale`,
    url: `${process.env.WEBSITE_URL}2025/agricultural-land-for-sale`,
  },
  {
    name: `Light Industrial Factory For Rent`,
    url: `${process.env.WEBSITE_URL}2025/light-industrial-factory-for-rent`,
  },
  {
    name: `Light Industrial Factory For Sale`,
    url: `${process.env.WEBSITE_URL}2025/light-industrial-factory-for-sale`,
  },
  {
    name: `Link Factory For Rent`,
    url: `${process.env.WEBSITE_URL}2025/link-factory-for-rent`,
  },
  {
    name: `Link Factory For Sale`,
    url: `${process.env.WEBSITE_URL}2025/link-factory-for-sale`,
  },
  {
    name: `Corner Lot Houses For Rent`,
    url: `${process.env.WEBSITE_URL}2025/corner-lot-houses-for-rent`,
  },
  {
    name: `Corner Lot Houses For Sale`,
    url: `${process.env.WEBSITE_URL}2025/corner-lot-houses-for-sale`,
  },
  {
    name: `House For Rent`,
    url: `${process.env.WEBSITE_URL}2025/house-for-rent`,
  },
  {
    name: `House For Sale`,
    url: `${process.env.WEBSITE_URL}2025/house-for-sale`,
  },
  {
    name: `Bare Unit For Sale`,
    url: `${process.env.WEBSITE_URL}2025/bare-unit-for-sale`,
  },
  {
    name: `Bare Unit For Rent`,
    url: `${process.env.WEBSITE_URL}2025/bare-unit-for-rent`,
  },
  {
    name: `Duplex Unit For Rent`,
    url: `${process.env.WEBSITE_URL}2025/duplex-unit-for-rent`,
  },
  {
    name: `Duplex Unit For Sale`,
    url: `${process.env.WEBSITE_URL}2025/duplex-unit-for-sale`,
  },
  {
    name: `Loft Apartment For Rent`,
    url: `${process.env.WEBSITE_URL}2025/loft-apartment-for-rent`,
  },
  {
    name: `Loft Apartment For Sale`,
    url: `${process.env.WEBSITE_URL}2025/loft-apartment-for-sale`,
  },
  {
    name: `Rooms For Sale`,
    url: `${process.env.WEBSITE_URL}2025/rooms-for-sale`,
  },
  {
    name: `Rooms For Rent`,
    url: `${process.env.WEBSITE_URL}2025/rooms-for-rent`,
  },
  {
    name: `Studio Unit For Rent`,
    url: `${process.env.WEBSITE_URL}2025/studio-unit-for-rent`,
  },
  {
    name: `Studio Unit For Sale`,
    url: `${process.env.WEBSITE_URL}2025/studio-unit-for-sale`,
  },
  {
    name: `Retail Space For Sale`,
    url: `${process.env.WEBSITE_URL}2025/retail-space-for-sale`,
  },
  {
    name: `Retail Space For Rent`,
    url: `${process.env.WEBSITE_URL}2025/retail-space-for-rent`,
  },
  {
    name: `Empty Land For Rent`,
    url: `${process.env.WEBSITE_URL}2025/empty-land-for-rent`,
  },
  {
    name: `Empty Land For Sale`,
    url: `${process.env.WEBSITE_URL}2025/empty-land-for-sale`,
  },
  {
    name: `Farm Land For Rent`,
    url: `${process.env.WEBSITE_URL}2025/farm-land-for-rent`,
  },
  {
    name: `Farm Land For Sale`,
    url: `${process.env.WEBSITE_URL}2025/farm-land-for-sale`,
  },
  {
    name: `Warehouse For Sale`,
    url: `${process.env.WEBSITE_URL}2025/warehouse-for-sale`,
  },
  {
    name: `Warehouse For Rent`,
    url: `${process.env.WEBSITE_URL}2025/warehouse-for-rent`,
  },
  {
    name: `Empty Office For Sale`,
    url: `${process.env.WEBSITE_URL}2025/empty-office-for-sale`,
  },
  {
    name: `Empty Office For Rent`,
    url: `${process.env.WEBSITE_URL}2025/empty-office-for-rent`,
  },
  {
    name: `Co Working Spaces For Sale`,
    url: `${process.env.WEBSITE_URL}2025/co-working-spaces-for-sale`,
  },
  {
    name: `Co Working Spaces For Rent`,
    url: `${process.env.WEBSITE_URL}2025/co-working-spaces-for-rent`,
  },
  {
    name: `Office Space For Sale`,
    url: `${process.env.WEBSITE_URL}2025/office-space-for-sale`,
  },
  {
    name: `Office Space For Rent`,
    url: `${process.env.WEBSITE_URL}2025/office-space-for-rent`,
  },
  {
    name: `Gated House For Rent`,
    url: `${process.env.WEBSITE_URL}2025/gated-house-for-rent`,
  },
  {
    name: `Gated House For Sale`,
    url: `${process.env.WEBSITE_URL}2025/gated-house-for-sale`,
  },
  {
    name: `Cheap House For Sale`,
    url: `${process.env.WEBSITE_URL}2025/cheap-house-for-sale`,
  },
  {
    name: `Cheap House For Rent`,
    url: `${process.env.WEBSITE_URL}2025/cheap-house-for-rent`,
  },
  {
    name: `New House For Sale`,
    url: `${process.env.WEBSITE_URL}2025/new-house-for-sale`,
  },
  {
    name: `New House For Rent`,
    url: `${process.env.WEBSITE_URL}2025/new-house-for-rent`,
  },
  {
    name: `High Rental Condo For Rent`,
    url: `${process.env.WEBSITE_URL}2025/high-rental-condo-for-rent`,
  },
  {
    name: `High Rental Condo For Sale`,
    url: `${process.env.WEBSITE_URL}2025/high-rental-condo-for-sale`,
  },
  {
    name: `Condo Big Balcony For Rent`,
    url: `${process.env.WEBSITE_URL}2025/condo-big-balcony-for-rent`,
  },
  {
    name: `Condo Big Balcony For Sale`,
    url: `${process.env.WEBSITE_URL}2025/condo-big-balcony-for-sale`,
  },
  {
    name: `Room For Sale`,
    url: `${process.env.WEBSITE_URL}2025/room-for-sale`,
  },
  {
    name: `Room For Rent`,
    url: `${process.env.WEBSITE_URL}2025/room-for-rent`,
  },
];

export const propertyTypeFeature2025 = [
  {
    name: `2 Storey Semi Detached For Sale with Tenure Freehold`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-semi-detached-for-sale/tenure-freehold`,
  },
  {
    name: `Condo For Sale with Tenure Freehold`,
    url: `${process.env.WEBSITE_URL}2025/condo-for-sale/tenure-freehold`,
  },
  {
    name: `Double Storey Semi D For Sale with Tenure Freehold`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-sale/tenure-freehold`,
  },
  {
    name: `Low Cost Apartment For Sale with Tenure Freehold`,
    url: `${
      process.env.WEBSITE_URL
    }2025/low-cost-apartment-for-sale/tenure-freehold`,
  },
  {
    name: `Semi D Factory For Sale with Tenure Freehold`,
    url: `${
      process.env.WEBSITE_URL
    }2025/semi-d-factory-for-sale/tenure-freehold`,
  },
  {
    name: `Shoplot For Sale with Tenure Freehold`,
    url: `${process.env.WEBSITE_URL}2025/shoplot-for-sale/tenure-freehold`,
  },
  {
    name: `Bungalow For Sale with Tenure Leasehold`,
    url: `${process.env.WEBSITE_URL}2025/bungalow-for-sale/tenure-leasehold`,
  },
  {
    name: `Commercial For Sale with Tenure Leasehold`,
    url: `${process.env.WEBSITE_URL}2025/commercial-for-sale/tenure-leasehold`,
  },
  {
    name: `Industrial Land For Sale with Tenure Leasehold`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-land-for-sale/tenure-leasehold`,
  },
  {
    name: `Office For Sale with Tenure Leasehold`,
    url: `${process.env.WEBSITE_URL}2025/office-for-sale/tenure-leasehold`,
  },
  {
    name: `2 Storey Terrace For Rent with Tenure Bumi Lot`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/tenure-bumi-lot`,
  },
  {
    name: `Bungalow For Rent with Tenure Bumi Lot`,
    url: `${process.env.WEBSITE_URL}2025/bungalow-for-rent/tenure-bumi-lot`,
  },
  {
    name: `Residential For Sale with Tenure Bumi Lot`,
    url: `${process.env.WEBSITE_URL}2025/residential-for-sale/tenure-bumi-lot`,
  },
  {
    name: `Serviced Condo For Sale with Tenure Bumi Lot`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/tenure-bumi-lot`,
  },
  {
    name: `Walk Up Apartment For Sale with Tenure Bumi Lot`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-sale/tenure-bumi-lot`,
  },
  {
    name: `2 Storey Terrace For Rent with Bathroom Min 2`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/bathroom-min-2`,
  },
  {
    name: `2 Storey Shoplot For Rent with Bathroom Min 2`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-shoplot-for-rent/bathroom-min-2`,
  },
  {
    name: `Bungalow For Rent with Bathroom Min 3`,
    url: `${process.env.WEBSITE_URL}2025/bungalow-for-rent/bathroom-min-3`,
  },
  {
    name: `Condo For Rent with Bathroom Min 2`,
    url: `${process.env.WEBSITE_URL}2025/condo-for-rent/bathroom-min-2`,
  },
  {
    name: `Condominium For Sale with Bathroom Min 2`,
    url: `${process.env.WEBSITE_URL}2025/condominium-for-sale/bathroom-min-2`,
  },
  {
    name: `Office For Sale with Bathroom Min 2`,
    url: `${process.env.WEBSITE_URL}2025/office-for-sale/bathroom-min-2`,
  },
  {
    name: `Residential Property For Sale with Bathroom Min 2`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-property-for-sale/bathroom-min-2`,
  },
  {
    name: `Shop Office For Sale with Bathroom Min 2`,
    url: `${process.env.WEBSITE_URL}2025/shop-office-for-sale/bathroom-min-2`,
  },
  {
    name: `Soho For Sale with Bathroom Min 2`,
    url: `${process.env.WEBSITE_URL}2025/soho-for-sale/bathroom-min-2`,
  },
  {
    name: `Walk Up Apartment For Sale with Bathroom Min 2`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-sale/bathroom-min-2`,
  },
  {
    name: `2 Storey Semi Detached For Sale with Bedroom Min 3`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-semi-detached-for-sale/bedroom-min-3`,
  },
  {
    name: `3 Storey Shoplot For Sale with Bedroom Min 4`,
    url: `${
      process.env.WEBSITE_URL
    }2025/3-storey-shoplot-for-sale/bedroom-min-4`,
  },
  {
    name: `Bungalow For Sale with Bedroom Min 4`,
    url: `${process.env.WEBSITE_URL}2025/bungalow-for-sale/bedroom-min-4`,
  },
  {
    name: `Condo For Sale with Bedroom Min 4`,
    url: `${process.env.WEBSITE_URL}2025/condo-for-sale/bedroom-min-4`,
  },
  {
    name: `Penthouses For Sale with Bedroom Min 4`,
    url: `${process.env.WEBSITE_URL}2025/penthouses-for-sale/bedroom-min-4`,
  },
  {
    name: `Serviced Condo For Sale with Bedroom Min 4`,
    url: `${process.env.WEBSITE_URL}2025/serviced-condo-for-sale/bedroom-min-4`,
  },
  {
    name: `Walk Up Apartment For Sale with Bedroom Min 3`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-sale/bedroom-min-3`,
  },
  {
    name: `Cluster House For Rent with Bathroom Min 1`,
    url: `${process.env.WEBSITE_URL}2025/cluster-house-for-rent/bathroom-min-1`,
  },
  {
    name: `Condo For Rent with Bathroom Min 1`,
    url: `${process.env.WEBSITE_URL}2025/condo-for-rent/bathroom-min-1`,
  },
  {
    name: `Residential Property For Rent with Bathroom Min 1`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-property-for-rent/bathroom-min-1`,
  },
  {
    name: `Soho For Rent with Bathroom Min 1`,
    url: `${process.env.WEBSITE_URL}2025/soho-for-rent/bathroom-min-1`,
  },
  {
    name: `Studio Apartment For Rent with Bathroom Min 1`,
    url: `${
      process.env.WEBSITE_URL
    }2025/studio-apartment-for-rent/bathroom-min-1`,
  },
  {
    name: `Walk Up Apartment For Rent with Bathroom Min 1`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-rent/bathroom-min-1`,
  },
  {
    name: `1 Storey Terrace For Rent with Price Between RM3,500-RM4,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/1-storey-terrace-for-rent/price-between-3500-4000`,
  },
  {
    name: `1 Storey Terrace For Rent with Price Below RM5,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/1-storey-terrace-for-rent/price-below-5000`,
  },
  {
    name: `2 Storey Semi Detached For Rent with Price Between RM3,500-RM4,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-semi-detached-for-rent/price-between-3500-4000`,
  },
  {
    name: `2 Storey Semi Detached For Rent with Price Below RM5,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-semi-detached-for-rent/price-below-5000`,
  },
  {
    name: `2 Storey Semi Detached For Rent with Price Between RM5,500-RM6,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-semi-detached-for-rent/price-between-5500-6000`,
  },
  {
    name: `2 Storey Terrace For Rent with Price Between RM5,500-RM6,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/price-between-5500-6000`,
  },
  {
    name: `2 Storey Terrace For Rent with Price Between RM7,000-RM7,500`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/price-between-7000-7500`,
  },
  {
    name: `Apartment Condo Service Residence For Rent with Price Between RM3,500-RM4,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent/price-between-3500-4000`,
  },
  {
    name: `Apartment Condo Service Residence For Rent with Price Below RM5,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent/price-below-5000`,
  },
  {
    name: `Apartment Condo Service Residence For Rent with Price Between RM5,500-RM6,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent/price-between-5500-6000`,
  },
  {
    name: `Apartment Condo Service Residence For Rent with Price Between RM7,000-RM7,500`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent/price-between-7000-7500`,
  },
  {
    name: `Condo For Rent with Price Between RM3,500-RM4,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-rent/price-between-3500-4000`,
  },
  {
    name: `Condo For Rent with Price Below RM5,000`,
    url: `${process.env.WEBSITE_URL}2025/condo-for-rent/price-below-5000`,
  },
  {
    name: `Double Storey Semi D For Rent with Price Between RM3,500-RM4,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-rent/price-between-3500-4000`,
  },
  {
    name: `Double Storey Semi D For Rent with Price Below RM5,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-rent/price-below-5000`,
  },
  {
    name: `Double Storey Semi D For Rent with Price Between RM5,500-RM6,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-rent/price-between-5500-6000`,
  },
  {
    name: `Office For Rent with Price Below RM5,000`,
    url: `${process.env.WEBSITE_URL}2025/office-for-rent/price-below-5000`,
  },
  {
    name: `Office For Rent with Price Between RM5,500-RM6,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-rent/price-between-5500-6000`,
  },
  {
    name: `Office For Rent with Price Between RM7,000-RM7,500`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-rent/price-between-7000-7500`,
  },
  {
    name: `Serviced Condo For Rent with Price Below RM5,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-rent/price-below-5000`,
  },
  {
    name: `Serviced Condo For Rent with Price Between RM5,500-RM6,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-rent/price-between-5500-6000`,
  },
  {
    name: `Serviced Condo For Rent with Price Between RM7,000-RM7,500`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-rent/price-between-7000-7500`,
  },
  {
    name: `Single Storey Bungalow For Rent with Price Between RM3,500-RM4,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/single-storey-bungalow-for-rent/price-between-3500-4000`,
  },
  {
    name: `Single Storey Bungalow For Rent with Price Below RM5,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/single-storey-bungalow-for-rent/price-below-5000`,
  },
  {
    name: `Single Storey Bungalow For Rent with Price Between RM5,500-RM6,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/single-storey-bungalow-for-rent/price-between-5500-6000`,
  },
  {
    name: `Studio Apartment For Rent with Price Between RM3,500-RM4,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/studio-apartment-for-rent/price-between-3500-4000`,
  },
  {
    name: `Studio Apartment For Rent with Price Below RM5,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/studio-apartment-for-rent/price-below-5000`,
  },
  {
    name: `2 Storey Terrace For Sale with Price Between RM600,000-RM900,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-sale/price-between-600000-900000`,
  },
  {
    name: `Bungalow For Sale with Price Between RM600,000-RM900,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/bungalow-for-sale/price-between-600000-900000`,
  },
  {
    name: `Detached Factory For Sale with Price Between RM600,000-RM900,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-sale/price-between-600000-900000`,
  },
  {
    name: `Double 2 Storey Bungalow For Sale with Price Between RM600,000-RM900,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-bungalow-for-sale/price-between-600000-900000`,
  },
  {
    name: `Light Industrial Factory For Sale with Price Between RM600,000-RM900,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/light-industrial-factory-for-sale/price-between-600000-900000`,
  },
  {
    name: `Residential Land For Sale with Price Between RM900,000-RM1,200,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-land-for-sale/price-between-900000-1200000`,
  },
  {
    name: `Semi D Factory For Sale with Price Between RM900,000-RM1,200,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/semi-d-factory-for-sale/price-between-900000-1200000`,
  },
  {
    name: `Shoplot For Sale with Price Between RM900,000-RM1,200,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/shoplot-for-sale/price-between-900000-1200000`,
  },
  {
    name: `Residential For Sale with Price Between RM1,200,000-RM1,500,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-for-sale/price-between-1200000-1500000`,
  },
  {
    name: `Residential Property For Sale with Price Between RM1,200,000-RM1,500,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-property-for-sale/price-between-1200000-1500000`,
  },
  {
    name: `Semi D Factory For Sale with Price Between RM1,200,000-RM1,500,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/semi-d-factory-for-sale/price-between-1200000-1500000`,
  },
  {
    name: `Shop Office For Sale with Price Between RM1,200,000-RM1,500,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/shop-office-for-sale/price-between-1200000-1500000`,
  },
  {
    name: `Walk Up Apartment For Sale with Price Between RM1,200,000-RM1,500,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-sale/price-between-1200000-1500000`,
  },
  {
    name: `2 Storey Shoplot For Sale with Price Between RM1,500,000-RM1,800,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-shoplot-for-sale/price-between-1500000-1800000`,
  },
  {
    name: `Industrial Land For Sale with Price Between RM2,400,000-RM2,700,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-land-for-sale/price-between-2400000-2700000`,
  },
  {
    name: `Agricultural Land For Sale with Price Between RM2,400,000-RM2,700,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/agricultural-land-for-sale/price-between-2400000-2700000`,
  },
  {
    name: `Commercial Property For Sale with Price Between RM2,700,000-RM3,000,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-property-for-sale/price-between-2700000-3000000`,
  },
  {
    name: `Residential For Sale with Price Between RM2,700,000-RM3,000,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-for-sale/price-between-2700000-3000000`,
  },
  {
    name: `Residential Land For Sale with Price Between RM2,700,000-RM3,000,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-land-for-sale/price-between-2700000-3000000`,
  },
  {
    name: `Agricultural Land For Sale with Price Between RM2,700,000-RM3,000,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/agricultural-land-for-sale/price-between-2700000-3000000`,
  },
  {
    name: `Agricultural Property For Sale with Price Between RM2,700,000-RM3,000,000`,
    url: `${
      process.env.WEBSITE_URL
    }2025/agricultural-property-for-sale/price-between-2700000-3000000`,
  },
  {
    name: `1 Storey Terrace For Sale with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/1-storey-terrace-for-sale/furnishing-partially-furnished`,
  },
  {
    name: `1 Storey Terrace For Rent with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/1-storey-terrace-for-rent/furnishing-partially-furnished`,
  },
  {
    name: `2 Storey Semi Detached For Sale with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-semi-detached-for-sale/furnishing-unfurnished`,
  },
  {
    name: `2 Storey Terrace For Sale with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-sale/furnishing-unfurnished`,
  },
  {
    name: `2 Storey Terrace For Rent with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/furnishing-unfurnished`,
  },
  {
    name: `Apartment Condo Service Residence For Sale with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-sale/furnishing-unfurnished`,
  },
  {
    name: `Apartment Condo Service Residence For Rent with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent/furnishing-unfurnished`,
  },
  {
    name: `Apartment Condo Service Residence For Sale with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-sale/furnishing-partially-furnished`,
  },
  {
    name: `Apartment Condo Service Residence For Rent with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent/furnishing-partially-furnished`,
  },
  {
    name: `Apartment Condo Service Residence For Sale with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-sale/furnishing-fully-furnished`,
  },
  {
    name: `Apartment Condo Service Residence For Rent with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent/furnishing-fully-furnished`,
  },
  {
    name: `Cluster Factory For Sale with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cluster-factory-for-sale/furnishing-fully-furnished`,
  },
  {
    name: `Cluster Factory For Rent with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cluster-factory-for-rent/furnishing-fully-furnished`,
  },
  {
    name: `Cluster House For Sale with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cluster-house-for-sale/furnishing-unfurnished`,
  },
  {
    name: `Cluster House For Rent with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cluster-house-for-rent/furnishing-unfurnished`,
  },
  {
    name: `Cluster House For Sale with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cluster-house-for-sale/furnishing-partially-furnished`,
  },
  {
    name: `Condo For Rent with Furnishing Unfurnished`,
    url: `${process.env.WEBSITE_URL}2025/condo-for-rent/furnishing-unfurnished`,
  },
  {
    name: `Condo For Sale with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-sale/furnishing-partially-furnished`,
  },
  {
    name: `Condo For Rent with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-rent/furnishing-partially-furnished`,
  },
  {
    name: `Condo For Sale with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-sale/furnishing-fully-furnished`,
  },
  {
    name: `Condo For Rent with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-rent/furnishing-fully-furnished`,
  },
  {
    name: `Condominium For Sale with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condominium-for-sale/furnishing-unfurnished`,
  },
  {
    name: `Double 2 Storey Bungalow For Rent with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-bungalow-for-rent/furnishing-fully-furnished`,
  },
  {
    name: `Double 2 Storey Shoplot For Sale with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-sale/furnishing-unfurnished`,
  },
  {
    name: `Double 2 Storey Shoplot For Rent with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-rent/furnishing-unfurnished`,
  },
  {
    name: `Double 2 Storey Shoplot For Sale with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-sale/furnishing-partially-furnished`,
  },
  {
    name: `Double 2 Storey Shoplot For Rent with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-rent/furnishing-partially-furnished`,
  },
  {
    name: `Double 2 Storey Shoplot For Sale with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-sale/furnishing-fully-furnished`,
  },
  {
    name: `Double 2 Storey Shoplot For Rent with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-rent/furnishing-fully-furnished`,
  },
  {
    name: `Office For Sale with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-sale/furnishing-partially-furnished`,
  },
  {
    name: `Office For Rent with Furnishing Partially Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-rent/furnishing-partially-furnished`,
  },
  {
    name: `Office For Sale with Furnishing Fully Furnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-sale/furnishing-fully-furnished`,
  },
  {
    name: `Serviced Condo For Sale with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/furnishing-unfurnished`,
  },
  {
    name: `Serviced Condo For Rent with Furnishing Unfurnished`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-rent/furnishing-unfurnished`,
  },
];

export const propertyTypeLocation2025 = [
  {
    name: `Semi D Factory For Rent in Jementah`,
    url: `${
      process.env.WEBSITE_URL
    }2025/semi-d-factory-for-rent/in-jementah-state-johor`,
  },
  {
    name: `Detached Factory For Sale in Menara Tsr Wangsa Maju`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-sale/in-menara-tsr-wangsa-maju-state-kuala-lumpur`,
  },
  {
    name: `3 Storey Shoplot For Rent in Quill City Mall`,
    url: `${
      process.env.WEBSITE_URL
    }2025/3-storey-shoplot-for-rent/in-quill-city-mall-state-kuala-lumpur`,
  },
  {
    name: `Low Cost Apartment For Rent in Menara Etiqa`,
    url: `${
      process.env.WEBSITE_URL
    }2025/low-cost-apartment-for-rent/in-menara-etiqa-state-kuala-lumpur`,
  },
  {
    name: `Shop Office For Sale in Bayu Segar`,
    url: `${
      process.env.WEBSITE_URL
    }2025/shop-office-for-sale/in-bayu-segar-state-kuala-lumpur`,
  },
  {
    name: `Industrial For Sale in Nusa Duta`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-sale/in-nusa-duta-state-johor`,
  },
  {
    name: `Terrace Factory For Rent in Padang Besar`,
    url: `${
      process.env.WEBSITE_URL
    }2025/terrace-factory-for-rent/in-padang-besar-state-perlis`,
  },
  {
    name: `Serviced Condo For Sale in Menara Heitech Village`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/in-menara-heitech-village-state-selangor`,
  },
  {
    name: `Condo For Sale in Kota Setar`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-sale/in-kota-setar-state-kedah`,
  },
  {
    name: `Soho For Sale in Taman Seri Cheras Jaya`,
    url: `${
      process.env.WEBSITE_URL
    }2025/soho-for-sale/in-taman-seri-cheras-jaya-state-selangor`,
  },
  {
    name: `Bungalow For Rent in Jeram`,
    url: `${
      process.env.WEBSITE_URL
    }2025/bungalow-for-rent/in-jeram-state-perak`,
  },
  {
    name: `Penthouses For Sale in Damansara Selangor`,
    url: `${
      process.env.WEBSITE_URL
    }2025/penthouses-for-sale/in-damansara-selangor-state-selangor`,
  },
  {
    name: `Industrial Land For Rent in Chemor`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-land-for-rent/in-chemor-state-perak`,
  },
  {
    name: `2 Storey Terrace For Rent in Muadzam Shah`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-muadzam-shah-state-pahang`,
  },
  {
    name: `Office For Sale in Empire Damansara Tower`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-sale/in-empire-damansara-tower-state-selangor`,
  },
  {
    name: `Detached Factory For Sale in Lang Tengah Island`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-sale/in-lang-tengah-island-state-terengganu`,
  },
  {
    name: `Bungalow For Rent in Sky Mirror Kuala Selangor`,
    url: `${
      process.env.WEBSITE_URL
    }2025/bungalow-for-rent/in-sky-mirror-kuala-selangor-state-selangor`,
  },
  {
    name: `Condo For Sale in Setia Ecohill 2`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-sale/in-setia-ecohill-2-state-selangor`,
  },
  {
    name: `Detached Factory For Rent in Jalan Matang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-rent/in-jalan-matang-state-sarawak`,
  },
  {
    name: `Double 2 Storey Bungalow For Rent in Labis`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-bungalow-for-rent/in-labis-state-johor`,
  },
  {
    name: `Sofo For Rent in Bau`,
    url: `${process.env.WEBSITE_URL}2025/sofo-for-rent/in-bau-state-sarawak`,
  },
  {
    name: `2 Storey Terrace For Rent in Pj Ss2`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-pj-ss2-state-selangor`,
  },
  {
    name: `Industrial For Rent in Sri Mahamariamman Temple`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-rent/in-sri-mahamariamman-temple-state-penang`,
  },
  {
    name: `Apartment Condo Service Residence For Rent in Johol`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent/in-johol-state-negeri-sembilan`,
  },
  {
    name: `Low Cost Apartment For Rent in Seberang Perai Utara`,
    url: `${
      process.env.WEBSITE_URL
    }2025/low-cost-apartment-for-rent/in-seberang-perai-utara-state-penang`,
  },
  {
    name: `Sofo For Rent in Country Heights Kajang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sofo-for-rent/in-country-heights-kajang-state-selangor`,
  },
  {
    name: `Link Factory For Rent in Menara Hsc`,
    url: `${
      process.env.WEBSITE_URL
    }2025/link-factory-for-rent/in-menara-hsc-state-kuala-lumpur`,
  },
  {
    name: `Terrace Factory For Sale in Wilayah Persekutuan`,
    url: `${
      process.env.WEBSITE_URL
    }2025/terrace-factory-for-sale/in-wilayah-persekutuan-state-kuala-lumpur`,
  },
  {
    name: `Industrial For Rent in Nu Tower 2`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-rent/in-nu-tower-2-state-kuala-lumpur`,
  },
  {
    name: `Low Cost Apartment For Sale in Menara Hap Seng Kota Kinabalu`,
    url: `${
      process.env.WEBSITE_URL
    }2025/low-cost-apartment-for-sale/in-menara-hap-seng-kota-kinabalu-state-sabah`,
  },
  {
    name: `Cluster House For Sale in Bandar Tenggara`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cluster-house-for-sale/in-bandar-tenggara-state-johor`,
  },
  {
    name: `Sofo For Rent in Serendah`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sofo-for-rent/in-serendah-state-selangor`,
  },
  {
    name: `Commercial For Sale in Mutiara Seputeh`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-sale/in-mutiara-seputeh-state-kuala-lumpur`,
  },
  {
    name: `Agricultural Land For Rent in Labis`,
    url: `${
      process.env.WEBSITE_URL
    }2025/agricultural-land-for-rent/in-labis-state-johor`,
  },
  {
    name: `Condominium For Rent in Kubang Kerian`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condominium-for-rent/in-kubang-kerian-state-kedah`,
  },
  {
    name: `Condo For Sale in Taman Tunoh`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-sale/in-taman-tunoh-state-sabah`,
  },
  {
    name: `Studio Apartment For Sale in Menara Perkeso`,
    url: `${
      process.env.WEBSITE_URL
    }2025/studio-apartment-for-sale/in-menara-perkeso-state-putrajaya`,
  },
  {
    name: `Office For Rent in Sundar`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-rent/in-sundar-state-sarawak`,
  },
  {
    name: `2 Storey Shoplot For Rent in Simpang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-shoplot-for-rent/in-simpang-state-perak`,
  },
  {
    name: `Single Storey Bungalow For Sale in Jalan Matang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/single-storey-bungalow-for-sale/in-jalan-matang-state-sarawak`,
  },
  {
    name: `2 Storey Shoplot For Sale in Pjs 11`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-shoplot-for-sale/in-pjs-11-state-selangor`,
  },
  {
    name: `Double 2 Storey Shoplot For Sale in Parit Sulong`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-sale/in-parit-sulong-state-johor`,
  },
  {
    name: `Commercial For Sale in Gurney Drive`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-sale/in-gurney-drive-state-penang`,
  },
  {
    name: `Detached Factory For Rent in Janda Baik Adventure Trails`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-rent/in-janda-baik-adventure-trails-state-pahang`,
  },
  {
    name: `Commercial For Rent in Johor Bahru Jb`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-rent/in-johor-bahru-jb-state-johor`,
  },
  {
    name: `Serviced Condo For Sale in Teluk Intan Leaning Tower`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/in-teluk-intan-leaning-tower-state-perak`,
  },
  {
    name: `Walk Up Apartment For Rent in Pulau Chondong`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-rent/in-pulau-chondong-state-kelantan`,
  },
  {
    name: `Single Storey Bungalow For Rent in Setapak Jaya`,
    url: `${
      process.env.WEBSITE_URL
    }2025/single-storey-bungalow-for-rent/in-setapak-jaya-state-kuala-lumpur`,
  },
  {
    name: `1 Storey Terrace For Sale in Senai`,
    url: `${
      process.env.WEBSITE_URL
    }2025/1-storey-terrace-for-sale/in-senai-state-johor`,
  },
  {
    name: `3 Storey Shoplot For Rent in Bukit Jugra Paragliding`,
    url: `${
      process.env.WEBSITE_URL
    }2025/3-storey-shoplot-for-rent/in-bukit-jugra-paragliding-state-selangor`,
  },
  {
    name: `Soho For Rent in Menara Hasil`,
    url: `${
      process.env.WEBSITE_URL
    }2025/soho-for-rent/in-menara-hasil-state-selangor`,
  },
  {
    name: `Double Storey Semi D For Rent in Lorong Sri Bukit Mas`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-rent/in-lorong-sri-bukit-mas-state-sabah`,
  },
  {
    name: `Industrial For Sale in Sungai Perak`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-sale/in-sungai-perak-state-perak`,
  },
  {
    name: `Serviced Condo For Sale in Lambor Kanan`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/in-lambor-kanan-state-perak`,
  },
  {
    name: `Detached Factory For Sale in Slim River`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-sale/in-slim-river-state-perak`,
  },
  {
    name: `Terrace Factory For Rent in Usj 17`,
    url: `${
      process.env.WEBSITE_URL
    }2025/terrace-factory-for-rent/in-usj-17-state-selangor`,
  },
  {
    name: `Office For Rent in Sauk`,
    url: `${process.env.WEBSITE_URL}2025/office-for-rent/in-sauk-state-perak`,
  },
  {
    name: `Office For Rent in Wisma Bapa Malaysia`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-rent/in-wisma-bapa-malaysia-state-sarawak`,
  },
  {
    name: `Sovo For Sale in Titiwangsa`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sovo-for-sale/in-titiwangsa-state-kuala-lumpur`,
  },
  {
    name: `Light Industrial Factory For Sale in Kuala Muda`,
    url: `${
      process.env.WEBSITE_URL
    }2025/light-industrial-factory-for-sale/in-kuala-muda-state-kedah`,
  },
  {
    name: `Residential Property For Sale in Pasir Panjang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-property-for-sale/in-pasir-panjang-state-negeri-sembilan`,
  },
  {
    name: `Penthouses For Sale in Taman Selayang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/penthouses-for-sale/in-taman-selayang-state-selangor`,
  },
  {
    name: `Detached Factory For Rent in Taman Pauh Jaya`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-rent/in-taman-pauh-jaya-state-penang`,
  },
  {
    name: `Studio Apartment For Sale in Johol`,
    url: `${
      process.env.WEBSITE_URL
    }2025/studio-apartment-for-sale/in-johol-state-negeri-sembilan`,
  },
  {
    name: `Residential For Sale in The Ascent Paradigm`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-for-sale/in-the-ascent-paradigm-state-selangor`,
  },
  {
    name: `Industrial Property For Sale in Pandan Indah`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-property-for-sale/in-pandan-indah-state-selangor`,
  },
  {
    name: `Industrial Property For Rent in Sunway Clio Tower`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-property-for-rent/in-sunway-clio-tower-state-selangor`,
  },
  {
    name: `Condo For Rent in Lubok China`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-rent/in-lubok-china-state-melaka`,
  },
  {
    name: `1 Storey Terrace For Sale in Hutan Melintang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/1-storey-terrace-for-sale/in-hutan-melintang-state-perak`,
  },
  {
    name: `Industrial For Rent in Pantai Klebang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-rent/in-pantai-klebang-state-melaka`,
  },
  {
    name: `Apartment Condo Service Residence For Sale in Tanjung Tualang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-sale/in-tanjung-tualang-state-perak`,
  },
  {
    name: `Sovo For Rent in Petronas Tower 3`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sovo-for-rent/in-petronas-tower-3-state-kuala-lumpur`,
  },
  {
    name: `Soho For Sale in Gua Keris`,
    url: `${
      process.env.WEBSITE_URL
    }2025/soho-for-sale/in-gua-keris-state-kelantan`,
  },
  {
    name: `Commercial Property For Rent in Taman Cheras`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-property-for-rent/in-taman-cheras-state-kuala-lumpur`,
  },
  {
    name: `Commercial For Rent in Simpang Durian`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-rent/in-simpang-durian-state-negeri-sembilan`,
  },
  {
    name: `Double 2 Storey Shoplot For Sale in Desa Bayanmas`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-sale/in-desa-bayanmas-state-kedah`,
  },
  {
    name: `Walk Up Apartment For Rent in Pantai Remis`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-rent/in-pantai-remis-state-perak`,
  },
  {
    name: `Condo For Rent in Bandar Utama`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-rent/in-bandar-utama-state-kuala-lumpur`,
  },
  {
    name: `Detached Factory For Sale in Pj Ss3`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-sale/in-pj-ss3-state-selangor`,
  },
  {
    name: `Terrace Factory For Sale in Muzium Negara`,
    url: `${
      process.env.WEBSITE_URL
    }2025/terrace-factory-for-sale/in-muzium-negara-state-kuala-lumpur`,
  },
  {
    name: `Medium Industrial Factory For Sale in Benta`,
    url: `${
      process.env.WEBSITE_URL
    }2025/medium-industrial-factory-for-sale/in-benta-state-pahang`,
  },
  {
    name: `Residential Property For Rent in Usj 20`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-property-for-rent/in-usj-20-state-selangor`,
  },
  {
    name: `Penthouses For Sale in Wisma Yeap Chor Ee`,
    url: `${
      process.env.WEBSITE_URL
    }2025/penthouses-for-sale/in-wisma-yeap-chor-ee-state-penang`,
  },
  {
    name: `Condo For Rent in Usj 12`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-rent/in-usj-12-state-selangor`,
  },
  {
    name: `2 Storey Shoplot For Sale in Wisma Mont Kiara`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-shoplot-for-sale/in-wisma-mont-kiara-state-kuala-lumpur`,
  },
  {
    name: `Residential Land For Sale in Japanese Village`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-land-for-sale/in-japanese-village-state-pahang`,
  },
  {
    name: `Industrial Land For Sale in Usj 10`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-land-for-sale/in-usj-10-state-selangor`,
  },
  {
    name: `2 Storey Terrace For Rent in Jalan Kiara`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-jalan-kiara-state-kuala-lumpur`,
  },
  {
    name: `Double Storey Semi D For Sale in Lawas`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-sale/in-lawas-state-sarawak`,
  },
  {
    name: `Semi D Factory For Sale in Valencia`,
    url: `${
      process.env.WEBSITE_URL
    }2025/semi-d-factory-for-sale/in-valencia-state-selangor`,
  },
  {
    name: `2 Storey Terrace For Rent in Tawang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-tawang-state-kelantan`,
  },
  {
    name: `Residential Land For Rent in Taman Suria Jaya`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-land-for-rent/in-taman-suria-jaya-state-selangor`,
  },
  {
    name: `Serviced Condo For Rent in Presint 6`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-rent/in-presint-6-state-putrajaya`,
  },
  {
    name: `Agricultural Property For Rent in Sungkai`,
    url: `${
      process.env.WEBSITE_URL
    }2025/agricultural-property-for-rent/in-sungkai-state-perak`,
  },
  {
    name: `Walk Up Apartment For Sale in Kinarut`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-sale/in-kinarut-state-sabah`,
  },
  {
    name: `Shoplot For Rent in Parit`,
    url: `${process.env.WEBSITE_URL}2025/shoplot-for-rent/in-parit-state-perak`,
  },
  {
    name: `Apartment Condo Service Residence For Sale in Kuala Lumpur City Center`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-sale/in-kuala-lumpur-city-center-state-kuala-lumpur`,
  },
  {
    name: `Double 2 Storey Bungalow For Rent in Wilayah Persekutuan`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-bungalow-for-rent/in-wilayah-persekutuan-state-kuala-lumpur`,
  },
  {
    name: `Residential For Sale in Endau Rompin National Park`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-for-sale/in-endau-rompin-national-park-state-pahang`,
  },
  {
    name: `Cluster House For Sale in Presint 16`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cluster-house-for-sale/in-presint-16-state-putrajaya`,
  },
  {
    name: `Double Storey Semi D For Sale in Pulau Payar Marine Park`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-sale/in-pulau-payar-marine-park-state-kedah`,
  },
  {
    name: `Sofo For Rent in Mengkarak`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sofo-for-rent/in-mengkarak-state-pahang`,
  },
  {
    name: `3 Storey Shoplot For Rent in Kek Lok Si Temple`,
    url: `${
      process.env.WEBSITE_URL
    }2025/3-storey-shoplot-for-rent/in-kek-lok-si-temple-state-penang`,
  },
  {
    name: `Serviced Condo For Sale in Langkawi Cable Car`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/in-langkawi-cable-car-state-kedah`,
  },
  {
    name: `Commercial For Rent in Sunway Nexis Office Suites`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-rent/in-sunway-nexis-office-suites-state-selangor`,
  },
  {
    name: `Condominium For Rent in Bota`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condominium-for-rent/in-bota-state-perak`,
  },
  {
    name: `Single Storey Bungalow For Sale in Taman Megah Mas`,
    url: `${
      process.env.WEBSITE_URL
    }2025/single-storey-bungalow-for-sale/in-taman-megah-mas-state-selangor`,
  },
  {
    name: `Penthouses For Sale in Gertak Sanggul`,
    url: `${
      process.env.WEBSITE_URL
    }2025/penthouses-for-sale/in-gertak-sanggul-state-penang`,
  },
  {
    name: `Double 2 Storey Shoplot For Rent in Taiping Zoo And Night Safari`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-rent/in-taiping-zoo-and-night-safari-state-perak`,
  },
  {
    name: `Double 2 Storey Bungalow For Sale in Manukan Island`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-bungalow-for-sale/in-manukan-island-state-sabah`,
  },
  {
    name: `2 Storey Terrace For Sale in Kahang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-sale/in-kahang-state-johor`,
  },
  {
    name: `2 Storey Terrace For Rent in Crystal Mosque`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-crystal-mosque-state-terengganu`,
  },
  {
    name: `Sofo For Sale in Pelabuhan Klang`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sofo-for-sale/in-pelabuhan-klang-state-selangor`,
  },
  {
    name: `Shop Office For Sale in Kenny Hills Bukit Tunku`,
    url: `${
      process.env.WEBSITE_URL
    }2025/shop-office-for-sale/in-kenny-hills-bukit-tunku-state-kuala-lumpur`,
  },
  {
    name: `2 Storey Terrace For Sale in Menara Ijm Land`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-sale/in-menara-ijm-land-state-penang`,
  },
  {
    name: `2 Storey Semi Detached For Rent in Plaza Mont Kiara`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-semi-detached-for-rent/in-plaza-mont-kiara-state-kuala-lumpur`,
  },
  {
    name: `Commercial For Sale in KLCC`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-sale/in-klcc-state-kuala-lumpur`,
  },
  {
    name: `Residential For Sale in Subang Ss15`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-for-sale/in-subang-ss15-state-selangor`,
  },
  {
    name: `Shoplot For Rent in Johor Bahru`,
    url: `${
      process.env.WEBSITE_URL
    }2025/shoplot-for-rent/in-johor-bahru-state-johor`,
  },
  {
    name: `Cheap House For Sale in Semenyih`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cheap-house-for-sale/in-semenyih-state-selangor`,
  },
];

export const propertyTypeLocationFeature2025 = [
  {
    name: `Semi D Factory For Rent in Jementah with Fully Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/semi-d-factory-for-rent/in-jementah-state-johor/furnishing-fully-furnished`,
  },
  {
    name: `Detached Factory For Sale in Menara Tsr Wangsa Maju with Unfurnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-sale/in-menara-tsr-wangsa-maju-state-kuala-lumpur/furnishing-unfurnished`,
  },
  {
    name: `3 Storey Shoplot For Rent in Quill City Mall with Partially Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/3-storey-shoplot-for-rent/in-quill-city-mall-state-kuala-lumpur/furnishing-partially-furnished`,
  },
  {
    name: `Low Cost Apartment For Rent in Menara Etiqa with Freehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/low-cost-apartment-for-rent/in-menara-etiqa-state-kuala-lumpur/tenure-freehold`,
  },
  {
    name: `Shop Office For Sale in Bayu Segar with Leasehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/shop-office-for-sale/in-bayu-segar-state-kuala-lumpur/tenure-leasehold`,
  },
  {
    name: `Industrial For Sale in Nusa Duta with Bumilot Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-sale/in-nusa-duta-state-johor/tenure-bumi-lot`,
  },
  {
    name: `Terrace Factory For Rent in Padang Besar with Minimum 1 Bedroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/terrace-factory-for-rent/in-padang-besar-state-perlis/bedroom-min-1`,
  },
  {
    name: `Serviced Condo For Sale in Menara Heitech Village with Minimum 2 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/in-menara-heitech-village-state-selangor/bedroom-min-2`,
  },
  {
    name: `Condo For Sale in Kota Setar with Minimum 3 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-sale/in-kota-setar-state-kedah/bedroom-min-3`,
  },
  {
    name: `Soho For Sale in Taman Seri Cheras Jaya with Minimum 4 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/soho-for-sale/in-taman-seri-cheras-jaya-state-selangor/bedroom-min-4`,
  },
  {
    name: `Bungalow For Rent in Jeram with Minimum 5 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/bungalow-for-rent/in-jeram-state-perak/bedroom-min-5`,
  },
  {
    name: `Penthouses For Sale in Damansara Selangor with Minimum 6 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/penthouses-for-sale/in-damansara-selangor-state-selangor/bedroom-min-6`,
  },
  {
    name: `Industrial Land For Rent in Chemor with Minimum 1 Bathroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-land-for-rent/in-chemor-state-perak/bathroom-min-1`,
  },
  {
    name: `2 Storey Terrace For Rent in Muadzam Shah with Minimum 2 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-muadzam-shah-state-pahang/bathroom-min-2`,
  },
  {
    name: `Office For Sale in Empire Damansara Tower with Minimum 3 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-sale/in-empire-damansara-tower-state-selangor/bathroom-min-3`,
  },
  {
    name: `Detached Factory For Sale in Lang Tengah Island with Minimum 4 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-sale/in-lang-tengah-island-state-terengganu/bathroom-min-4`,
  },
  {
    name: `Bungalow For Rent in Sky Mirror Kuala Selangor with Minimum 5 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/bungalow-for-rent/in-sky-mirror-kuala-selangor-state-selangor/bathroom-min-5`,
  },
  {
    name: `Condo For Sale in Setia Ecohill 2 with Minimum 6 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-sale/in-setia-ecohill-2-state-selangor/bathroom-min-6`,
  },
  {
    name: `Detached Factory For Rent in Jalan Matang with Fully Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-rent/in-jalan-matang-state-sarawak/furnishing-fully-furnished`,
  },
  {
    name: `Double 2 Storey Bungalow For Rent in Labis with Unfurnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-bungalow-for-rent/in-labis-state-johor/furnishing-unfurnished`,
  },
  {
    name: `Sofo For Rent in Bau with Partially Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sofo-for-rent/in-bau-state-sarawak/furnishing-partially-furnished`,
  },
  {
    name: `2 Storey Terrace For Rent in Pj Ss2 with Freehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-pj-ss2-state-selangor/tenure-freehold`,
  },
  {
    name: `Industrial For Rent in Sri Mahamariamman Temple with Leasehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-rent/in-sri-mahamariamman-temple-state-penang/tenure-leasehold`,
  },
  {
    name: `Apartment Condo Service Residence For Rent in Johol with Bumilot Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-rent/in-johol-state-negeri-sembilan/tenure-bumi-lot`,
  },
  {
    name: `Low Cost Apartment For Rent in Seberang Perai Utara with Minimum 1 Bedroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/low-cost-apartment-for-rent/in-seberang-perai-utara-state-penang/bedroom-min-1`,
  },
  {
    name: `Sofo For Rent in Country Heights Kajang with Minimum 2 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sofo-for-rent/in-country-heights-kajang-state-selangor/bedroom-min-2`,
  },
  {
    name: `Link Factory For Rent in Menara Hsc with Minimum 3 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/link-factory-for-rent/in-menara-hsc-state-kuala-lumpur/bedroom-min-3`,
  },
  {
    name: `Terrace Factory For Sale in Wilayah Persekutuan with Minimum 4 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/terrace-factory-for-sale/in-wilayah-persekutuan-state-kuala-lumpur/bedroom-min-4`,
  },
  {
    name: `Industrial For Rent in Nu Tower 2 with Minimum 5 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-rent/in-nu-tower-2-state-kuala-lumpur/bedroom-min-5`,
  },
  {
    name: `Low Cost Apartment For Sale in Menara Hap Seng Kota Kinabalu with Minimum 6 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/low-cost-apartment-for-sale/in-menara-hap-seng-kota-kinabalu-state-sabah/bedroom-min-6`,
  },
  {
    name: `Cluster House For Sale in Bandar Tenggara with Minimum 1 Bathroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cluster-house-for-sale/in-bandar-tenggara-state-johor/bathroom-min-1`,
  },
  {
    name: `Sofo For Rent in Serendah with Minimum 2 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sofo-for-rent/in-serendah-state-selangor/bathroom-min-2`,
  },
  {
    name: `Commercial For Sale in Mutiara Seputeh with Minimum 3 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-sale/in-mutiara-seputeh-state-kuala-lumpur/bathroom-min-3`,
  },
  {
    name: `Agricultural Land For Rent in Labis with Minimum 4 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/agricultural-land-for-rent/in-labis-state-johor/bathroom-min-4`,
  },
  {
    name: `Condominium For Rent in Kubang Kerian with Minimum 5 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condominium-for-rent/in-kubang-kerian-state-kedah/bathroom-min-5`,
  },
  {
    name: `Condo For Sale in Taman Tunoh with Minimum 6 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-sale/in-taman-tunoh-state-sabah/bathroom-min-6`,
  },
  {
    name: `Studio Apartment For Sale in Menara Perkeso with Fully Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/studio-apartment-for-sale/in-menara-perkeso-state-putrajaya/furnishing-fully-furnished`,
  },
  {
    name: `Office For Rent in Sundar with Unfurnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-rent/in-sundar-state-sarawak/furnishing-unfurnished`,
  },
  {
    name: `2 Storey Shoplot For Rent in Simpang with Partially Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-shoplot-for-rent/in-simpang-state-perak/furnishing-partially-furnished`,
  },
  {
    name: `Single Storey Bungalow For Sale in Jalan Matang with Freehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/single-storey-bungalow-for-sale/in-jalan-matang-state-sarawak/tenure-freehold`,
  },
  {
    name: `2 Storey Shoplot For Sale in Pjs 11 with Leasehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-shoplot-for-sale/in-pjs-11-state-selangor/tenure-leasehold`,
  },
  {
    name: `Double 2 Storey Shoplot For Sale in Parit Sulong with Bumilot Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-sale/in-parit-sulong-state-johor/tenure-bumi-lot`,
  },
  {
    name: `Commercial For Sale in Gurney Drive with Minimum 1 Bedroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-sale/in-gurney-drive-state-penang/bedroom-min-1`,
  },
  {
    name: `Detached Factory For Rent in Janda Baik Adventure Trails with Minimum 2 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-rent/in-janda-baik-adventure-trails-state-pahang/bedroom-min-2`,
  },
  {
    name: `Commercial For Rent in Johor Bahru Jb with Minimum 3 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-rent/in-johor-bahru-jb-state-johor/bedroom-min-3`,
  },
  {
    name: `Serviced Condo For Sale in Teluk Intan Leaning Tower with Minimum 4 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/in-teluk-intan-leaning-tower-state-perak/bedroom-min-4`,
  },
  {
    name: `Walk Up Apartment For Rent in Pulau Chondong with Minimum 5 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-rent/in-pulau-chondong-state-kelantan/bedroom-min-5`,
  },
  {
    name: `Single Storey Bungalow For Rent in Setapak Jaya with Minimum 6 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/single-storey-bungalow-for-rent/in-setapak-jaya-state-kuala-lumpur/bedroom-min-6`,
  },
  {
    name: `1 Storey Terrace For Sale in Senai with Minimum 1 Bathroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/1-storey-terrace-for-sale/in-senai-state-johor/bathroom-min-1`,
  },
  {
    name: `3 Storey Shoplot For Rent in Bukit Jugra Paragliding with Minimum 2 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/3-storey-shoplot-for-rent/in-bukit-jugra-paragliding-state-selangor/bathroom-min-2`,
  },
  {
    name: `Soho For Rent in Menara Hasil with Minimum 3 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/soho-for-rent/in-menara-hasil-state-selangor/bathroom-min-3`,
  },
  {
    name: `Double Storey Semi D For Rent in Lorong Sri Bukit Mas with Minimum 4 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-rent/in-lorong-sri-bukit-mas-state-sabah/bathroom-min-4`,
  },
  {
    name: `Industrial For Sale in Sungai Perak with Minimum 5 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-sale/in-sungai-perak-state-perak/bathroom-min-5`,
  },
  {
    name: `Serviced Condo For Sale in Lambor Kanan with Minimum 6 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/in-lambor-kanan-state-perak/bathroom-min-6`,
  },
  {
    name: `Detached Factory For Sale in Slim River with Fully Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-sale/in-slim-river-state-perak/furnishing-fully-furnished`,
  },
  {
    name: `Terrace Factory For Rent in Usj 17 with Unfurnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/terrace-factory-for-rent/in-usj-17-state-selangor/furnishing-unfurnished`,
  },
  {
    name: `Office For Rent in Sauk with Partially Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-rent/in-sauk-state-perak/furnishing-partially-furnished`,
  },
  {
    name: `Office For Rent in Wisma Bapa Malaysia with Freehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/office-for-rent/in-wisma-bapa-malaysia-state-sarawak/tenure-freehold`,
  },
  {
    name: `Sovo For Sale in Titiwangsa with Leasehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sovo-for-sale/in-titiwangsa-state-kuala-lumpur/tenure-leasehold`,
  },
  {
    name: `Light Industrial Factory For Sale in Kuala Muda with Bumilot Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/light-industrial-factory-for-sale/in-kuala-muda-state-kedah/tenure-bumi-lot`,
  },
  {
    name: `Residential Property For Sale in Pasir Panjang with Minimum 1 Bedroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-property-for-sale/in-pasir-panjang-state-negeri-sembilan/bedroom-min-1`,
  },
  {
    name: `Penthouses For Sale in Taman Selayang with Minimum 2 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/penthouses-for-sale/in-taman-selayang-state-selangor/bedroom-min-2`,
  },
  {
    name: `Detached Factory For Rent in Taman Pauh Jaya with Minimum 3 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-rent/in-taman-pauh-jaya-state-penang/bedroom-min-3`,
  },
  {
    name: `Studio Apartment For Sale in Johol with Minimum 4 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/studio-apartment-for-sale/in-johol-state-negeri-sembilan/bedroom-min-4`,
  },
  {
    name: `Residential For Sale in The Ascent Paradigm with Minimum 5 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-for-sale/in-the-ascent-paradigm-state-selangor/bedroom-min-5`,
  },
  {
    name: `Industrial Property For Sale in Pandan Indah with Minimum 6 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-property-for-sale/in-pandan-indah-state-selangor/bedroom-min-6`,
  },
  {
    name: `Industrial Property For Rent in Sunway Clio Tower with Minimum 1 Bathroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-property-for-rent/in-sunway-clio-tower-state-selangor/bathroom-min-1`,
  },
  {
    name: `Condo For Rent in Lubok China with Minimum 2 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-rent/in-lubok-china-state-melaka/bathroom-min-2`,
  },
  {
    name: `1 Storey Terrace For Sale in Hutan Melintang with Minimum 3 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/1-storey-terrace-for-sale/in-hutan-melintang-state-perak/bathroom-min-3`,
  },
  {
    name: `Industrial For Rent in Pantai Klebang with Minimum 4 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-for-rent/in-pantai-klebang-state-melaka/bathroom-min-4`,
  },
  {
    name: `Apartment Condo Service Residence For Sale in Tanjung Tualang with Minimum 5 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-sale/in-tanjung-tualang-state-perak/bathroom-min-5`,
  },
  {
    name: `Sovo For Rent in Petronas Tower 3 with Minimum 6 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sovo-for-rent/in-petronas-tower-3-state-kuala-lumpur/bathroom-min-6`,
  },
  {
    name: `Soho For Sale in Gua Keris with Fully Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/soho-for-sale/in-gua-keris-state-kelantan/furnishing-fully-furnished`,
  },
  {
    name: `Commercial Property For Rent in Taman Cheras with Unfurnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-property-for-rent/in-taman-cheras-state-kuala-lumpur/furnishing-unfurnished`,
  },
  {
    name: `Commercial For Rent in Simpang Durian with Partially Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-rent/in-simpang-durian-state-negeri-sembilan/furnishing-partially-furnished`,
  },
  {
    name: `Double 2 Storey Shoplot For Sale in Desa Bayanmas with Freehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-sale/in-desa-bayanmas-state-kedah/tenure-freehold`,
  },
  {
    name: `Walk Up Apartment For Rent in Pantai Remis with Leasehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-rent/in-pantai-remis-state-perak/tenure-leasehold`,
  },
  {
    name: `Condo For Rent in Bandar Utama with Bumilot Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-rent/in-bandar-utama-state-kuala-lumpur/tenure-bumi-lot`,
  },
  {
    name: `Detached Factory For Sale in Pj Ss3 with Minimum 1 Bedroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/detached-factory-for-sale/in-pj-ss3-state-selangor/bedroom-min-1`,
  },
  {
    name: `Terrace Factory For Sale in Muzium Negara with Minimum 2 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/terrace-factory-for-sale/in-muzium-negara-state-kuala-lumpur/bedroom-min-2`,
  },
  {
    name: `Medium Industrial Factory For Sale in Benta with Minimum 3 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/medium-industrial-factory-for-sale/in-benta-state-pahang/bedroom-min-3`,
  },
  {
    name: `Residential Property For Rent in Usj 20 with Minimum 4 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-property-for-rent/in-usj-20-state-selangor/bedroom-min-4`,
  },
  {
    name: `Penthouses For Sale in Wisma Yeap Chor Ee with Minimum 5 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/penthouses-for-sale/in-wisma-yeap-chor-ee-state-penang/bedroom-min-5`,
  },
  {
    name: `Condo For Rent in Usj 12 with Minimum 6 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condo-for-rent/in-usj-12-state-selangor/bedroom-min-6`,
  },
  {
    name: `2 Storey Shoplot For Sale in Wisma Mont Kiara with Minimum 1 Bathroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-shoplot-for-sale/in-wisma-mont-kiara-state-kuala-lumpur/bathroom-min-1`,
  },
  {
    name: `Residential Land For Sale in Japanese Village with Minimum 2 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-land-for-sale/in-japanese-village-state-pahang/bathroom-min-2`,
  },
  {
    name: `Industrial Land For Sale in Usj 10 with Minimum 3 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/industrial-land-for-sale/in-usj-10-state-selangor/bathroom-min-3`,
  },
  {
    name: `2 Storey Terrace For Rent in Jalan Kiara with Minimum 4 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-jalan-kiara-state-kuala-lumpur/bathroom-min-4`,
  },
  {
    name: `Double Storey Semi D For Sale in Lawas with Minimum 5 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-sale/in-lawas-state-sarawak/bathroom-min-5`,
  },
  {
    name: `Semi D Factory For Sale in Valencia with Minimum 6 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/semi-d-factory-for-sale/in-valencia-state-selangor/bathroom-min-6`,
  },
  {
    name: `2 Storey Terrace For Rent in Tawang with Fully Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-tawang-state-kelantan/furnishing-fully-furnished`,
  },
  {
    name: `Residential Land For Rent in Taman Suria Jaya with Unfurnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-land-for-rent/in-taman-suria-jaya-state-selangor/furnishing-unfurnished`,
  },
  {
    name: `Serviced Condo For Rent in Presint 6 with Partially Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-rent/in-presint-6-state-putrajaya/furnishing-partially-furnished`,
  },
  {
    name: `Agricultural Property For Rent in Sungkai with Freehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/agricultural-property-for-rent/in-sungkai-state-perak/tenure-freehold`,
  },
  {
    name: `Walk Up Apartment For Sale in Kinarut with Leasehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/walk-up-apartment-for-sale/in-kinarut-state-sabah/tenure-leasehold`,
  },
  {
    name: `Shoplot For Rent in Parit with Bumilot Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/shoplot-for-rent/in-parit-state-perak/tenure-bumi-lot`,
  },
  {
    name: `Apartment Condo Service Residence For Sale in Kuala Lumpur City Center with Minimum 1 Bedroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/apartment-condo-service-residence-for-sale/in-kuala-lumpur-city-center-state-kuala-lumpur/bedroom-min-1`,
  },
  {
    name: `Double 2 Storey Bungalow For Rent in Wilayah Persekutuan with Minimum 2 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-bungalow-for-rent/in-wilayah-persekutuan-state-kuala-lumpur/bedroom-min-2`,
  },
  {
    name: `Residential For Sale in Endau Rompin National Park with Minimum 3 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-for-sale/in-endau-rompin-national-park-state-pahang/bedroom-min-3`,
  },
  {
    name: `Cluster House For Sale in Presint 16 with Minimum 4 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cluster-house-for-sale/in-presint-16-state-putrajaya/bedroom-min-4`,
  },
  {
    name: `Double Storey Semi D For Sale in Pulau Payar Marine Park with Minimum 5 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-storey-semi-d-for-sale/in-pulau-payar-marine-park-state-kedah/bedroom-min-5`,
  },
  {
    name: `Sofo For Rent in Mengkarak with Minimum 6 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sofo-for-rent/in-mengkarak-state-pahang/bedroom-min-6`,
  },
  {
    name: `3 Storey Shoplot For Rent in Kek Lok Si Temple with Minimum 1 Bathroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/3-storey-shoplot-for-rent/in-kek-lok-si-temple-state-penang/bathroom-min-1`,
  },
  {
    name: `Serviced Condo For Sale in Langkawi Cable Car with Minimum 2 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/serviced-condo-for-sale/in-langkawi-cable-car-state-kedah/bathroom-min-2`,
  },
  {
    name: `Commercial For Rent in Sunway Nexis Office Suites with Minimum 3 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-rent/in-sunway-nexis-office-suites-state-selangor/bathroom-min-3`,
  },
  {
    name: `Condominium For Rent in Bota with Minimum 4 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/condominium-for-rent/in-bota-state-perak/bathroom-min-4`,
  },
  {
    name: `Single Storey Bungalow For Sale in Taman Megah Mas with Minimum 5 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/single-storey-bungalow-for-sale/in-taman-megah-mas-state-selangor/bathroom-min-5`,
  },
  {
    name: `Penthouses For Sale in Gertak Sanggul with Minimum 6 Bathrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/penthouses-for-sale/in-gertak-sanggul-state-penang/bathroom-min-6`,
  },
  {
    name: `Double 2 Storey Shoplot For Rent in Taiping Zoo And Night Safari with Fully Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-shoplot-for-rent/in-taiping-zoo-and-night-safari-state-perak/furnishing-fully-furnished`,
  },
  {
    name: `Double 2 Storey Bungalow For Sale in Manukan Island with Unfurnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/double-2-storey-bungalow-for-sale/in-manukan-island-state-sabah/furnishing-unfurnished`,
  },
  {
    name: `2 Storey Terrace For Sale in Kahang with Partially Furnished Furnishing`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-sale/in-kahang-state-johor/furnishing-partially-furnished`,
  },
  {
    name: `2 Storey Terrace For Rent in Crystal Mosque with Freehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-rent/in-crystal-mosque-state-terengganu/tenure-freehold`,
  },
  {
    name: `Sofo For Sale in Pelabuhan Klang with Leasehold Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/sofo-for-sale/in-pelabuhan-klang-state-selangor/tenure-leasehold`,
  },
  {
    name: `Shop Office For Sale in Kenny Hills Bukit Tunku with Bumilot Tenure`,
    url: `${
      process.env.WEBSITE_URL
    }2025/shop-office-for-sale/in-kenny-hills-bukit-tunku-state-kuala-lumpur/tenure-bumi-lot`,
  },
  {
    name: `2 Storey Terrace For Sale in Menara Ijm Land with Minimum 1 Bedroom`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-terrace-for-sale/in-menara-ijm-land-state-penang/bedroom-min-1`,
  },
  {
    name: `2 Storey Semi Detached For Rent in Plaza Mont Kiara with Minimum 2 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/2-storey-semi-detached-for-rent/in-plaza-mont-kiara-state-kuala-lumpur/bedroom-min-2`,
  },
  {
    name: `Commercial For Sale in KLCC with Minimum 3 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/commercial-for-sale/in-klcc-state-kuala-lumpur/bedroom-min-3`,
  },
  {
    name: `Residential For Sale in Subang Ss15 with Minimum 4 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/residential-for-sale/in-subang-ss15-state-selangor/bedroom-min-4`,
  },
  {
    name: `Shoplot For Rent in Johor Bahru with Minimum 5 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/shoplot-for-rent/in-johor-bahru-state-johor/bedroom-min-5`,
  },
  {
    name: `Cheap House For Sale in Semenyih with Minimum 6 Bedrooms`,
    url: `${
      process.env.WEBSITE_URL
    }2025/cheap-house-for-sale/in-semenyih-state-selangor/bedroom-min-6`,
  },
];
