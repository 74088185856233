import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Color, Color as Colors } from "../../src/utils/Color";
import styled, { withTheme, useTheme } from "styled-components";
import { connect } from "dva";
import Image from "next/image";
import _ from "lodash";
import Router from "next/router";
import { NextSeo } from "next-seo";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomFont from "../../components/CustomFont";
import Divider from "@material-ui/core/Divider";
import Button from "@mui/material/Button";
import { maxDevice } from "../../src/utils/breakpoints";
import * as selectors from "../../src/selectors";
import * as searchActions from "../../src/actions/search";
import Hidden from "@material-ui/core/Hidden";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import RoomIcon from "@mui/icons-material/Room";
import CallIcon from "@material-ui/icons/Call";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const ImageContainer = styled.div`
  width: 240px;
  height: 100px;
  position: relative;
  margin: 20px 0;
  @media ${maxDevice.md} {
    width: 200px;
  }
`;
const ImageContainerContent = styled.div`
  @media ${maxDevice.md} {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
`;
const FooterIconContent = styled.div`
  margin: 10px 0;
  display: flex;
  font-size: 0.9rem;
  &:hover {
    color: ${Colors.primaryColor};
    transition: 200ms ease-in;
  }
`;
const FooterSocicalMediaContent = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${maxDevice.md} {
    justify-content: flex-start;
  }
`;
const GridStyled = styled(Grid)`
  @media ${maxDevice.md} {
    &.MuiGrid-container {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }
`;
const FooterLinkStyled = styled.a`
  color: ${Colors.blackColor};
  font-size: 0.9rem;
  &:hover {
    color: ${Colors.primaryColor};
    transition: 200ms ease-in;
  }
  @media ${maxDevice.md} {
    font-size: 0.9rem;
  }
`;
const FooterLinkStyledIcon = styled.a`
  background-color: ${Colors.blackColor};
  width: 36px;
  height: 36px;

  &:hover {
    background-color: ${Colors.primaryColor};
    transition: 200ms ease-in;
  }
  @media ${maxDevice.md} {
    width: 36px;
    height: 36px;
  }
`;

const InstaIconStyled = styled.div`
  width: 100%;
  position: relative;
  aspect-ratio: 3/2;
  position: relative;
  // @media ${maxDevice.md} {
  //
  //   }
  }
`;

class FooterCopyRight extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ marginBottom: "30px" }}>
        {/*=======================Copyright section==============================*/}
        <Divider
          style={{
            backgroundColor: "#2c2c2c",
            height: "2px",
            marginTop: "2rem",
            marginBottom: "1rem"
          }}
        />
        <div>
          <GridStyled
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item md={8}>
              <CustomFont size="small">
                {`© 2025 PropertyGenie Sdn Bhd. All rights reserved.`}
              </CustomFont>
            </Grid>
            <Grid item md={4}>
              <FooterSocicalMediaContent>
                <FooterLinkStyledIcon
                  href={`https://t.me/propertygeniemy`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    position: "relative",
                    borderRadius: "50%",
                    marginRight: "10px"
                  }}
                  aria-label="PropertyGenie Telegram Hyperlink"
                >
                  <TelegramIcon
                    sx={{
                      position: "absolute",
                      color: Colors.textWhite,
                      top: "6px",
                      right: "6px"
                    }}
                  />
                </FooterLinkStyledIcon>
                <FooterLinkStyledIcon
                  href="https://www.youtube.com/@PropertyGeniemy"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    position: "relative",
                    borderRadius: "50%",
                    marginRight: "10px"
                  }}
                  aria-label="PropertyGenie YouTube Hyperlink"
                >
                  <YouTubeIcon
                    sx={{
                      position: "absolute",
                      color: Colors.textWhite,
                      top: "6px",
                      right: "6px"
                    }}
                  />
                </FooterLinkStyledIcon>
                <FooterLinkStyledIcon
                  href="https://www.facebook.com/propertygenie.com.my"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    position: "relative",
                    borderRadius: "50%",
                    marginRight: "10px"
                  }}
                  aria-label="PropertyGenie Facebook Hyperlink"
                >
                  <FacebookIcon
                    sx={{
                      position: "absolute",
                      color: Colors.textWhite,
                      top: "6px",
                      right: "6px"
                    }}
                  />
                </FooterLinkStyledIcon>
                <FooterLinkStyledIcon
                  href="https://www.instagram.com/propertygenie.com.my/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    position: "relative",
                    borderRadius: "50%",
                    marginRight: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  aria-label="PropertyGenie Instagram Hyperlink"
                >
                  <InstagramIcon
                    sx={{
                      position: "absolute",
                      color: Colors.textWhite,
                      top: "6px",
                      right: "6px"
                    }}
                  />
                  {/*<InstaIconStyled>*/}
                  {/*<a href={"https://www.instagram.com/propertygenie.com.my/"}>*/}
                  {/*  <Image*/}
                  {/*    src="/images/icons8-instagram.png"*/}
                  {/*    layout="fill"*/}
                  {/*    objectFit="contain"*/}
                  {/*    alt="instagram"*/}
                  {/*  />*/}
                  {/*</a>*/}
                  {/*</InstaIconStyled>*/}
                </FooterLinkStyledIcon>
              </FooterSocicalMediaContent>
            </Grid>
          </GridStyled>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FooterCopyRight)
);
