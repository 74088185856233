/*eslint-disable*/
import React, { Component } from "react";

// @material-ui/core components
import List from "@material-ui/core/List";

import TextField from "@mui/material/TextField";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Button from "@material-ui/core/Button";
import SearchIcon from "@mui/icons-material/Search";
import Router from "next/router";
import styled from "styled-components";
import { withTheme } from "styled-components";
import { maxDevice, device } from "../../src/utils/breakpoints";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@mui/material/Divider";
import { Color as Colors, Color } from "../../src/utils/Color";
import { isTablet, browserName } from "react-device-detect";
import * as selectors from "../../src/selectors";
import { connect } from "dva";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import ListItem from "@material-ui/core/ListItem";
import CustomFont from "../../components/CustomFont";
import Popover from "@mui/material/Popover";
import _ from "lodash";
import Constant from "../../src/constants";
import Helpers from "../../src/utils/Helpers";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { getAuth, signOut } from "firebase/auth";
import * as authActions from "../../src/actions/auth";
import * as profileSelectors from "../../src/selectors/profile";
import Cookies from "js-cookie";
import Constants from "../../src/constants";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Modal from "@material-ui/core/Modal";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LoginPageModal from "../../components/SignInModal";
import { getProfileAttributeHasActiveSubscription } from "../../src/selectors/profile";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Menu, MenuItem } from "@mui/material";

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: white;
  max-height: 80%;
  padding: 1rem 2rem;
  width: 70%;
  max-width: 650px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${maxDevice.sm} {
    padding: 1rem 1rem;
    width: 90%;
    max-width: 500px;
  }
`;

const TitleThankYou = styled.span`
  @media ${maxDevice.md} {
    font-size: 33px;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const CustomModalBoxAfterSubmittedInner = styled.div`
  background: ${Color.primaryColor};
  border-radius: 10px;
  padding: 25px;
  margin-top: -105px;
  z-index: 999999;
  position: relative;
`;

const ListItemContainer = styled.li`
  color: inherit;
  position: relative;
  display: block;
  width: auto;
  margin: 0;
  padding: 0;
  float: left;

  //&:hover {
  //  cursor: pointer;
  //}

  @media ${maxDevice.mxl} {
    float: none;
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  color: inherit;
  position: relative;
  padding: 0.6rem;
  font-weight: 600;
  font-size: 1em;
  text-transform: none;
  border-radius: 3px;
  line-height: 20px;
  text-decoration: none;
  margin: 0px;
  display: inline-flex;
  transition: opacity 2s linear;
  &:hover,
  &:focus {
    color: inherit;
    background: rgba(200, 200, 200, 0.2);
    transition: all 150ms ease 0s;
  }

  &:hover .DropDownLogin {
    display: block;
  }

  @media (max-width: 1279px) {
    color: ${Color.textWhite};
    font-size: 20px;
    padding: 1.5rem 0.6rem;
    align-items: center;
    &:hover,
    &:focus {
      color: ${Color.textWhite};
      background: rgba(200, 200, 200, 0.2);
    }
  }
`;

const DropDownLogin = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #dddddd;
  z-index: 1;
  margin-top: 40px;
  position: absolute;
  right: 0;
  background: white;

  & a {
    color: ${Colors.blackColor};
    padding: 12px 16px;
  }
  & a:first-child {
    border-bottom: 1px solid #dddddd;
  }
`;

const NormalContainer = styled.div`
  color: inherit;
  position: relative;
  padding: 0.6rem;
  font-weight: 600;
  font-size: 1em;
  text-transform: none;
  border-radius: 3px;
  line-height: 20px;
  text-decoration: none;
  margin: 0px;
  display: inline-flex;
  @media ${maxDevice.mxl} {
    color: ${Color.textWhite};
    font-size: 20px;
    padding: 1.5rem 0.6rem;
    align-items: center;
  }
`;

const SignInButton = styled.div`
  color: inherit;
  position: relative;
  padding: 0.6rem;
  font-weight: 600;
  font-size: 1em;
  text-transform: none;
  border-radius: 5px;
  line-height: 20px;
  text-decoration: none;
  margin: 0px;
  display: inline-flex;
  transition: all 150ms ease 0s;
  &:hover,
  &:focus {
    color: inherit;
    background: rgba(200, 200, 200, 0.2);
  }
  @media ${maxDevice.mxl} {
    color: ${Color.primaryColor};
    background: white;
    font-size: 20px;
    width: 100%;
    padding: 1.2rem 0.6rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    &:hover,
    &:focus {
      color: ${Color.textWhite};
      background: rgba(200, 200, 200, 0.2);
    }
  }
`;

const ListTabletItemContainer = styled.div`
  color: inherit;
  position: relative;
  display: block;
  width: auto;
  margin: 0;
  padding: 0;
  float: none;
`;

const TabletButtonContainer = styled.div`
  position: relative;
  font-weight: 600;
  text-transform: none;
  border-radius: 3px;
  line-height: 20px;
  text-decoration: none;
  margin: 0px;
  display: inline-flex;
  color: white;
  font-size: 20px;
  padding: 1.5rem 0.6rem;
  &:hover,
  &:focus {
    color: ${Color.textWhite};
    background: rgba(200, 200, 200, 0.2);
  }
`;
const TabletLogoutButtonContainer = styled.div`
  color: inherit;
  position: relative;
  padding: 0.6rem;
  font-weight: 600;
  font-size: 1em;
  text-transform: none;
  border-radius: 5px;
  line-height: 20px;
  text-decoration: none;
  margin: 0px;
  display: inline-flex;
  color: white;
  background: ${Color.primaryRed};
  font-size: 20px;
  width: 100%;
  padding: 1.2rem 0.6rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  &:hover,
  &:focus {
    color: ${Color.textWhite};
    opacity: 0.6;
  }
`;

const LogoutButton = styled.div`
  color: inherit;
  position: relative;
  padding: 0.6rem;
  font-weight: 600;
  font-size: 1em;
  text-transform: none;
  border-radius: 5px;
  line-height: 20px;
  text-decoration: none;
  margin: 0px;
  display: inline-flex;
  &:hover,
  &:focus {
    color: inherit;
    opacity: 0.6;
  }

  @media ${maxDevice.xxl} {
    color: white;
    background: ${Color.primaryRed};
    font-size: 20px;
    width: 100%;
    padding: 1.2rem 0.6rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    &:hover,
    &:focus {
      color: ${Color.textWhite};
      opacity: 0.6;
    }
  }
`;

const DesktopHidden = styled.div`
  //display: block;
  display: flex;

  @media (max-width: 1279px) {
    display: none;
  }
`;
const MobileHidden = styled.div`
  display: none;

  @media (max-width: 1279px) {
    display: block;
  }
`;

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      visible: false,
      logoutDialogVisible: false,
      isModalVisible: false,
      signUpDialogVisible: false,
    };
  }

  onClickSearch = (e) => {
    e.preventDefault();

    Router.push({
      pathname: "/property",
      query: { page: "search" },
    });
  };

  onClickLoginModal = () => {
    this.setState({ isModalVisible: false });
  };

  onClickLogin = (e) => {
    e.preventDefault();
    const inFifteenMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
    Cookies.set(
      Constants.COOKIES_REDIRECT_URL,
      window.location.pathname + window.location.search,
      {
        expires: inFifteenMinutes,
      }
    );

    if (window.location.pathname === "/login") {
      this.setState({ signUpDialogVisible: false });
    } else {
      Router.push("/login");
    }
  };

  onClickSignUp = () => {
    this.setState({ signUpDialogVisible: true });
  };

  onClickAgentSignUp = (e) => {
    e.preventDefault();

    if (window.location.pathname === "/agent-sign-up") {
      this.setState({ signUpDialogVisible: false });
    } else {
      Router.push("/agent-sign-up");
    }
  };

  onCloseSignUpDialog = () => {
    this.setState({
      signUpDialogVisible: false,
    });
  };

  onClickAgentLogin = (e) => {
    e.preventDefault();

    Router.push("/agent-sign-in");
  };
  onClickPremierAgent = (e) => {
    e.preventDefault();

    Router.push("/premier-agent");
  };

  handleClose = () => {
    this.setState({ isModalVisible: false });
  };

  onClickProfile = (e) => {
    e.preventDefault();
    this.setState({ anchorEl: e.currentTarget }, () => {
      this.setState({ visible: true });
    });
    //  Router.push("/profile");
  };

  onClickAccount = (e) => {
    // Router.push("/profile");
    const currentURL = window.location.pathname;
    // const targetURL = "/profile";
    const targetURL = "/agent/account";

    if (currentURL !== targetURL) {
      Router.push(targetURL);
    } else {
      e.preventDefault();
    }
  };

  onClickReferral = (e) => {
    // Router.push("/profile");
    const currentURL = window.location.pathname;
    const targetURL = "/referral";

    if (currentURL !== targetURL) {
      Router.push(targetURL);
    } else {
      e.preventDefault();
    }
  };
  onClickPurchaseHistory = (e) => {
    // Router.push("/profile");
    const currentURL = window.location.pathname;
    const targetURL = "/agent/purchase-history";

    if (currentURL !== targetURL) {
      Router.push(targetURL);
    } else {
      e.preventDefault();
    }
  };

  onClickProfilePage = (e) => {
    // Router.push("/profile");
    const currentURL = window.location.pathname;
    const targetURL = "/profile";

    if (currentURL !== targetURL) {
      Router.push(targetURL);
    } else {
      e.preventDefault();
    }
  };

  handlePopoverClose = () => {
    this.setState({ visible: false, anchorEl: null });
  };

  onClickLogout = () => {
    this.setState({ logoutDialogVisible: true });
  };

  onCloseDialog = () => {
    this.setState({
      logoutDialogVisible: false,
    });
  };

  onPressLogout = () => {
    const { logoutRequest } = this.props;
    this.onCloseDialog();
    logoutRequest && logoutRequest();
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  // onClickAgent = ( isAgent = false) => {
  //   if (isAgent) {
  //     Router.push("/agent");
  //   } else {
  //     Router.push("/be-agent");
  //   }
  // };

  onClickAgent = (isAgent = false, e) => {
    const currentURL = window.location.pathname;
    const targetURL = isAgent ? "/agent" : "/be-agent";

    if (currentURL !== targetURL) {
      Router.push(targetURL);
    } else {
      e.preventDefault();
    }
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (url) => {
    this.setState({ anchorEl: null });
    if (url) {
      window.location.href = url; // Redirect when a menu item is clicked
    }
  };

  render() {
    const {
      visible,
      anchorEl,
      logoutDialogVisible,
      signUpDialogVisible,
      isModalVisible,
    } = this.state;
    const { theme, authToken, meData } = this.props;
    const isAgent = !_.isEmpty(profileSelectors.getProfileAgent(meData));
    // const hasSubscript = !_.isEmpty(profileSelectors.getProfileAttributeHasActiveSubscription(meData));
    const hasSubscript = _.get(
      meData,
      ["attribute", "has_active_subscription"],
      []
    );
    // console.log("hasSubscript", hasSubscript)
    // console.log("meData", meData)

    const isEnableReferrer = profileSelectors.getReferralHistoryListEnableReferrer(
      meData
    );
    const open = Boolean(anchorEl);

    return (
      <div>
        <List
          style={{
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "300",
            lineHeight: "1.5em",
            fontSize: "14px",
            margin: 0,
            paddingLeft: "0",
            listStyle: "none",
            paddingTop: "0",
            paddingBottom: "0",
            color: "inherit",
          }}
        >
          <Hidden lgUp>
            <Divider
              style={{
                borderColor: Color.textWhite,
                borderWidth: "1px",
                opacity: 1,
              }}
            />
          </Hidden>

          <ListItemContainer style={{ display: "flex" }}>
            {/*=======Desktop View=========*/}
            <DesktopHidden>
              {!_.isEmpty(authToken) ? (
                <>
                  {!hasSubscript ? (
                    <Button
                      onClick={this.onClickPremierAgent}
                      // href={`${process.env.WEBSITE_URL}login`}
                      size="medium"
                      style={{
                        backgroundColor: Color.orangeTextColor,
                        color: Color.blackColor,
                        fontFamily: "Poppins, sans-serif",
                      }}
                      variant="contained"
                    >
                      Join as Agent
                    </Button>
                  ) : (
                    false
                  )}
                  <ButtonContainer
                    color="transparent"
                    // className={classes.navLink}
                    onClick={this.onClickProfile}
                    style={{ cursor: "pointer" }}
                    href={`${process.env.WEBSITE_URL}profile`}
                  >
                    <AccountCircleOutlinedIcon
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "3px",
                        marginTop: "3px",
                      }}
                    />
                  </ButtonContainer>
                </>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {hasSubscript ? (
                    <div>
                      <Button
                        onClick={(event) =>
                          this.setState({ anchorEl: event.currentTarget })
                        }
                        // onClick={this.onClickPremierAgent}
                        // href={`${process.env.WEBSITE_URL}login`}
                        size="medium"
                        style={{
                          backgroundColor: Color.orangeTextColor,
                          color: Color.blackColor,
                          marginTop: "-3px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                        variant="contained"
                      >
                        Join as Agent
                      </Button>
                      <Popover
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                        }}
                      >
                        {/*=======Desktop Version======*/}
                        <List style={{ width: "150px" }}>
                          <ListItem>
                            <a
                              href={`${process.env.WEBSITE_URL}login`}
                              onClick={this.onClickPremierAgent}
                            >
                              <CustomFont size="small">Sign Up</CustomFont>
                            </a>
                          </ListItem>
                          <ListItem>
                            <a
                              href={`${process.env.WEBSITE_URL}agent-sign-in`}
                              onClick={this.onClickAgentLogin}
                            >
                              <CustomFont size="small">Log In</CustomFont>
                            </a>
                          </ListItem>
                        </List>
                      </Popover>
                    </div>
                  ) : (
                    false
                  )}
                  <ButtonContainer
                    color="transparent"
                    style={{ marginTop: "-3px" }}
                    // className={classes.navLink}
                  >
                    <Button
                      onClick={this.onClickSignUp}
                      // href={`${process.env.WEBSITE_URL}login`}
                      size="medium"
                      style={{
                        backgroundColor: Color.primaryColor,
                        color: Color.textWhite,
                        fontFamily: "Poppins, sans-serif",
                      }}
                      variant="contained"
                    >
                      Sign Up
                    </Button>
                    {/* <DropDownLogin className="DropDownLogin">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <a
                        href={`${process.env.WEBSITE_URL}login`}
                        onClick={this.onClickLogin}
                      >
                        Login as User
                      </a>
                      <a href={`${process.env.WEBSITE_URL}agent-sign-in`}>
                        Login as Agent
                      </a>
                    </div>
                  </DropDownLogin> */}
                  </ButtonContainer>
                </div>
              )}
            </DesktopHidden>
            {/*============Mobile/Tablet Version==================*/}
            <MobileHidden>
              {isTablet ? (
                !_.isEmpty(authToken) ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <TabletButtonContainer
                      color="transparent"
                      // onClick={this.onClickAccount}
                      // className={classes.navLink}
                    >
                      <AccountCircleOutlinedIcon
                        style={{
                          width: "48px",
                          height: "48px",
                          marginRight: "10px",
                          marginTop: "3px",
                        }}
                      />
                    </TabletButtonContainer>
                    {isAgent ? (
                      <TabletButtonContainer
                        color="transparent"
                        style={{ color: Color.textWhite }}
                        href={`${process.env.WEBSITE_URL}${
                          isAgent ? "agent" : "be-agent"
                        }`}
                        onClick={(e) => this.onClickAgent(isAgent, e)}
                        // className={classes.navLink}
                      >
                        {!isAgent ? "Become Agent" : "Agent Portal"}
                      </TabletButtonContainer>
                    ) : (
                      false
                    )}
                    {isAgent ? (
                      <TabletButtonContainer
                        color="transparent"
                        // href={`${process.env.WEBSITE_URL}profile`}
                        // onClick={this.onClickAccount}
                        // className={classes.navLink}
                      >
                        <a
                          href={`${process.env.WEBSITE_URL}agent/account`}
                          onClick={(e) => this.onClickAccount(e)}
                        >
                          <CustomFont
                            size="tlarge"
                            hover
                            styles={{
                              color: Color.textWhite,
                              fontWeight: "600",
                            }}
                          >
                            {" "}
                            My Account
                          </CustomFont>
                        </a>
                      </TabletButtonContainer>
                    ) : (
                      <TabletButtonContainer
                        color="transparent"
                        // href={`${process.env.WEBSITE_URL}profile`}
                        // onClick={this.onClickAccount}
                        // className={classes.navLink}
                      >
                        <a
                          href={`${process.env.WEBSITE_URL}profile`}
                          onClick={(e) => this.onClickProfilePage(e)}
                        >
                          <CustomFont
                            size="tlarge"
                            hover
                            styles={{
                              color: Color.textWhite,
                              fontWeight: "600",
                            }}
                          >
                            {" "}
                            My Account
                          </CustomFont>
                        </a>
                      </TabletButtonContainer>
                    )}
                    <TabletLogoutButtonContainer
                      color="transparent"
                      // className={classes.navLink}
                      onClick={this.onClickLogout}
                      //style={{ color: Color.primaryRed }}
                    >
                      Sign Out
                    </TabletLogoutButtonContainer>
                  </div>
                ) : (
                  // <TabletButtonContainer
                  //   color="transparent"
                  //   onClick={this.onClickLogin}
                  //   href={`${process.env.WEBSITE_URL}login`}
                  //   // className={classes.navLink}
                  // >
                  //   Sign Inss
                  // </TabletButtonContainer>
                  <SignInButton
                    color="transparent"
                    onClick={(e) => this.onClickLogin(e)}
                    href={`${process.env.WEBSITE_URL}login`}
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    Login as User
                  </SignInButton>
                )
              ) : !_.isEmpty(authToken) ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <NormalContainer
                    color="transparent"
                    // className={classes.navLink}
                    // onClick={this.onClickAccount}
                  >
                    <AccountCircleOutlinedIcon
                      style={{
                        width: "48px",
                        height: "48px",
                        marginRight: "10px",
                        marginTop: "3px",
                      }}
                    />
                  </NormalContainer>
                  {isAgent ? (
                    <ButtonContainer
                      color="transparent"
                      // className={classes.navLink}
                      style={{ color: Color.textWhite }}
                      href={`${process.env.WEBSITE_URL}${
                        isAgent ? "agent" : "be-agent"
                      }`}
                      onClick={(e) => this.onClickAgent(isAgent, e)}
                    >
                      {!isAgent ? "Become Agent" : "Agent Portal"}
                    </ButtonContainer>
                  ) : (
                    false
                  )}
                  {isAgent ? (
                    <ButtonContainer
                      color="transparent"
                      // className={classes.navLink}
                      // href={`${process.env.WEBSITE_URL}profile`}
                      // onClick={this.onClickAccount}
                      href={`${process.env.WEBSITE_URL}agent/account`}
                      onClick={(e) => this.onClickAccount(e)}
                    >
                      My Account
                      {/*Accounts*/}
                    </ButtonContainer>
                  ) : (
                    <ButtonContainer
                      color="transparent"
                      // className={classes.navLink}
                      // href={`${process.env.WEBSITE_URL}profile`}
                      // onClick={this.onClickAccount}
                      href={`${process.env.WEBSITE_URL}profile`}
                      onClick={(e) => this.onClickProfilePage(e)}
                    >
                      My Account
                      {/*Accounts*/}
                    </ButtonContainer>
                  )}
                  {/*{isEnableReferrer ? (*/}
                  <ButtonContainer 
                    color="transparent"
                    href={`${process.env.WEBSITE_URL}referral`}
                    onClick={(e) => this.onClickReferral(e)}
                  >
                    My Referrals
                    {/*Accounts*/}
                  </ButtonContainer>
                  {isAgent ? (
                    <ButtonContainer
                      color="transparent"
                      // className={classes.navLink}
                      // href={`${process.env.WEBSITE_URL}profile`}
                      // onClick={this.onClickAccount}
                      href={`${
                        process.env.WEBSITE_URL
                      }agent/purchase-history`}
                      onClick={(e) => this.onClickPurchaseHistory(e)}
                    >
                      Purchase History
                      {/*Accounts*/}
                    </ButtonContainer>
                  ) : (
                    false
                  )}
                  {/*) : (*/}
                  {/*    false*/}
                  {/*)}*/}
                  {/*// ========== Join As Agent (AgentID + UserID Display) ========== */}
                  {!hasSubscript ? (
                    <>
                      <br />
                      <Button
                        onClick={this.onClickPremierAgent}
                        // href={`${process.env.WEBSITE_URL}login`}
                        size="medium"
                        style={{
                          backgroundColor: Color.orangeTextColor,
                          color: Color.blackColor,
                          width: "100%",
                          padding: "0.8rem 0.6rem",
                          fontSize: "20px",
                          fontWeight: "600",
                          textTransform: "initial",
                          fontFamily: "Poppins, sans-serif",
                        }}
                        variant="contained"
                      >
                        Join as Agent
                      </Button>
                    </>
                  ) : (
                    false
                  )}
                  {/*// ========== End of Join As Agent (AgentID + UserID Display) ========== */}
                  <LogoutButton
                    color="transparent"
                    // className={classes.navLink}
                    onClick={this.onClickLogout}
                    //style={{ color: Color.primaryRed }}
                  >
                    Sign Out
                  </LogoutButton>
                </div>
              ) : (
                // <SignInButton
                //   color="transparent"
                //   onClick={this.onClickLogin}
                //   href={`${process.env.WEBSITE_URL}login`}
                //   // className={classes.navLink}
                //   //style={{ color: "white" }}
                // >
                //   Sign In
                // </SignInButton>

                <div style={{ width: "100%" }}>
                  <SignInButton
                    color="transparent"
                    onClick={this.onClickLogin}
                    href={`${process.env.WEBSITE_URL}login`}
                  >
                    Login as User
                  </SignInButton>
                </div>
              )}
              <br />
              <div />
              {/*=====Standby use for 2 button logins purpose======*/}
              {!_.isEmpty(authToken) ? (
                isAgent ? (
                  <SignInButton
                    color="transparent"
                    onClick={this.onClickAgentLogin}
                    // href={`${process.env.WEBSITE_URL}agent-sign-in`}
                    style={{ display: "none", marginTop: "0px" }}
                  >
                    Login as Agent
                  </SignInButton>
                ) : (
                  <SignInButton
                    color="transparent"
                    onClick={this.onClickLogin}
                    href={`${process.env.WEBSITE_URL}login`}
                    style={{ display: "none", marginTop: "20px" }}
                  >
                    Login as User
                  </SignInButton>
                )
              ) : (
                <>
                  <SignInButton
                    color="transparent"
                    onClick={this.onClickAgentLogin}
                    // href={`${process.env.WEBSITE_URL}agent-sign-in`}
                    style={{
                      display: isAgent ? "none" : "block",
                      marginTop: "0px",
                    }}
                  >
                    Login as Agent
                  </SignInButton>
                  <br />
                  <Button
                    onClick={this.onClickPremierAgent}
                    // href={`${process.env.WEBSITE_URL}login`}
                    size="medium"
                    style={{
                      backgroundColor: Color.orangeTextColor,
                      color: Color.blackColor,
                      width: "100%",
                      padding: "0.8rem 0.6rem",
                      fontSize: "20px",
                      fontWeight: "600",
                      textTransform: "initial",
                      fontFamily: "Poppins, sans-serif",
                    }}
                    variant="contained"
                  >
                    Join as Agent
                  </Button>
                </>
              )}
            </MobileHidden>
          </ListItemContainer>
          <Popover
            open={visible}
            anchorEl={anchorEl}
            onClose={this.handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: -60,
            }}
          >
            {/*=======Desktop Version======*/}
            <List style={{ borderTop: `4px solid ${Color.primaryColor}` }}>
              {isAgent ? (
                <ListItem>
                  <a
                    href={`${process.env.WEBSITE_URL}${
                      isAgent ? "agent" : "be-agent"
                    }`}
                    // onClick={e => this.onClickAgent(isAgent, e)}
                  >
                    <CustomFont
                      hover
                      styles={{
                        color: isAgent ? Color.blackColor : Color.primaryRed,
                      }}
                    >
                      {!isAgent ? "Become Agent" : "Agent Portal"}
                    </CustomFont>
                  </a>
                </ListItem>
              ) : (
                false
              )}
              {isAgent ? (
                <ListItem style={{ width: "100%" }}>
                  <Divider
                    style={{
                      opacity: 1,
                      width: "100%",
                      border: 0,
                      borderTop: `1px solid ${Color.blackColor}`,
                    }}
                  />
                </ListItem>
              ) : (
                false
              )}
              {isAgent ? (
                <ListItem>
                  <a
                    // href={`${process.env.WEBSITE_URL}profile`}
                    href={`${process.env.WEBSITE_URL}agent/account`}
                    onClick={(e) => this.onClickAccount(e)}
                  >
                    <CustomFont hover>My Account</CustomFont>
                  </a>
                </ListItem>
              ) : (
                <ListItem>
                  <a
                    href={`${process.env.WEBSITE_URL}profile`}
                    onClick={(e) => this.onClickProfilePage(e)}
                  >
                    <CustomFont hover>My Account</CustomFont>
                  </a>
                </ListItem>
              )}
              {/*{isEnableReferrer ? (*/}
              <ListItem>
                <a
                  href={`${process.env.WEBSITE_URL}referral`}
                  onClick={(e) => this.onClickReferral(e)}
                >
                  <CustomFont hover>My Referrals</CustomFont>
                </a>
              </ListItem>
              {/*) : (*/}
              {/*    false*/}
              {/*)}*/}
              {isAgent ? (
                <ListItem>
                  <a
                    href={`${process.env.WEBSITE_URL}agent/purchase-history`}
                    onClick={(e) => this.onClickPurchaseHistory(e)}
                  >
                    <CustomFont hover>Purchase History</CustomFont>
                  </a>
                </ListItem>
              ) : (
                false
              )}
              <ListItem onClick={this.onClickLogout}>
                <CustomFont hover styles={{ color: Color.primaryRed }}>
                  Sign Out
                </CustomFont>
              </ListItem>
            </List>
          </Popover>
          <Dialog
            open={logoutDialogVisible}
            onClose={this.onCloseDialog}
            // PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle
              style={{
                cursor: "move",
                fontWeight: "600",
                borderBottom: "1px solid #C3C3C3",
              }}
              id="draggable-dialog-title"
            >
              <CustomFont
                size="large"
                styles={{ fontWeight: "600", color: Color.primaryColor }}
              >
                Sign Out
              </CustomFont>
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: Color.blackColor }}>
                <CustomFont
                  size="normal"
                  styles={{ padding: "5px 11px", margin: "0" }}
                >
                  Are you sure to sign out?
                </CustomFont>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.61) inset",
                  color: Color.iconColor,
                }}
                onClick={this.onCloseDialog}
              >
                No
              </Button>
              <Button
                style={{
                  color: Color.textWhite,
                  background: Color.primaryColor,
                  fontWeight: "600",
                }}
                onClick={this.onPressLogout}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </List>
        {/*===========Modal for LOGIN===========*/}
        {/*<Modal*/}
        {/*  open={isModalVisible}*/}
        {/*  onClose={this.handleClose}*/}
        {/*  aria-labelledby="modal-modal-title"*/}
        {/*  aria-describedby="modal-modal-description"*/}
        {/*>*/}
        {/*  <StyledBox>*/}
        {/*    <CloseButtonContainer>*/}
        {/*      <IconButton*/}
        {/*        style={{ zIndex: "999" }}*/}
        {/*        aria-label="delete"*/}
        {/*        onClick={this.handleClose}*/}
        {/*      >*/}
        {/*        <CancelOutlinedIcon*/}
        {/*          fontSize="inherit"*/}
        {/*          style={{ color: Colors.blackColor, fontSize: "30px" }}*/}
        {/*        />*/}
        {/*      </IconButton>*/}
        {/*    </CloseButtonContainer>*/}
        {/*    <div style={{ position: "relative" }}>*/}
        {/*      <LoginPageModal onClickLoginModal={this.onClickLoginModal} />*/}
        {/*    </div>*/}
        {/*  </StyledBox>*/}
        {/*</Modal>*/}
        <Dialog
          open={signUpDialogVisible}
          onClose={this.onCloseSignUpDialog}
          // PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="xs"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#e3f2fd",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              margin: "36px auto 6px auto",
            }}
          >
            <PriorityHighIcon
              style={{
                fontSize: "32px",
                borderRadius: "50%",
                border: `1.5px solid ${Color.primaryColor}`,
                padding: "5px",
              }}
              htmlColor={Color.primaryColor}
            />
          </div>
          <IconButton
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
            onClick={this.onCloseSignUpDialog}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ textAlign: "center" }}>
            <CustomFont size="large" styles={{ fontWeight: "600" }}>
              Choose Your User Type
            </CustomFont>
            <DialogContentText style={{ color: Color.blackColor }}>
              <CustomFont size="normal" styles={{ margin: "0" }}>
                Please select how you would like to use our platform
              </CustomFont>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "12px" }}
          >
            <Button
              variant="contained"
              style={{
                // boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.61) inset",
                background: Color.orangeTextColor,
                color: Color.blackColor,
                fontWeight: "600",
                width: "40%",
              }}
              onClick={this.onClickAgentSignUp}
            >
              Agent
            </Button>
            <Button
              variant="contained"
              style={{
                color: Color.textWhite,
                background: Color.primaryColor,
                fontWeight: "600",
                width: "40%",
              }}
              onClick={this.onClickLogin}
            >
              Property Seeker
            </Button>
          </DialogActions>
        </Dialog>
        {/*===========End of Modal for LOGIN===========*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: selectors.getAuthToken(state),
    meData: selectors.getProfileData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return { logoutRequest: () => dispatch(authActions.requestLogout()) };
}
export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderLinks)
);
